import * as THREE from 'three';

export default class GeometryUtils
{
    constructor(_options)
    {
        this.getCircleGeometry = (radius, segments) =>
        {
            const geometry = new THREE.BufferGeometry();
            let positions = [];
            for (var i = 0; i <= segments; i++)
            {
                var theta = (i / segments) * Math.PI * 2;
                var x = Math.cos(theta) * radius;
                var y = Math.sin(theta) * radius;
                positions.push(x, y, 0);
            }
            geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
            return geometry;
        };
    }
}