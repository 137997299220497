import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core.js';
import Event from '../Event.js';
import PanelBase from './PanelBase.js';


// NOTE-240117      파티클 원을 담고 있음
export default class AboutPanel extends PanelBase {
    constructor(_options) {
        super(_options);

        this.structure = _options.structure;
        this.core = new Core();
        this.event = new Event();
        this.textureLoader = this.core.textureLoader;
        this.layer = this.core.layer;

        this.awake();

        this.event.on(this.event.INIT_LOAD_STARTED, () => {
            this.load();
        });

        this.event.on(this.event.UI_MENULINE_HOVERED, (name) => {
            if (name == 'about')
                this.fillCircle.onhover();
        });
        this.event.on(this.event.UI_MENULINE_HOVEROUTED, (name) => {
            if (name == 'about')
                this.fillCircle.onhoverOut();
        });
    }

    awake() {
        this.group.rotation.y = THREE.MathUtils.degToRad(180);
        this.group.rotation.z = THREE.MathUtils.degToRad(180);

        this.initParticleCircleOpacity = 0.4;

        this.buttonPosObj = new THREE.Mesh(new THREE.BoxGeometry(0.1, 0.1, 0.1), new THREE.MeshBasicMaterial());
        this.buttonPosObj.position.set(7.2, 0, 0);
        this.buttonPosObj.visible = false;
        this.group.add(this.buttonPosObj);

        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, () => {
            this.adjustMenuButtonPos();
        });
    }

    load() {
        this.textureLoader.load('./images/Circle.png',
            (texture) => {
                this.particleTexture = texture;
                this.draw();
            });
    }

    draw() {
        // NOTE-240117      Particle Circle 그리는 부분
        const particleGeometry = new THREE.BufferGeometry();
        const particleMaterial = new THREE.PointsMaterial({
            size: 0.05,
            alphaMap: this.particleTexture,
            alphaTest: 0,
            transparent: true,
            opacity: this.initParticleCircleOpacity
        });

        const particleCount = 2000;
        const positions = new Float32Array(particleCount * 3);

        for (let i = 0; i < positions.length; i += 3) {
            const radius = Math.random() * this.mainCircleRadius;
            const theta = Math.random() * Math.PI * 2;

            positions[i] = radius * Math.cos(theta);
            positions[i + 1] = radius * Math.sin(theta);
            positions[i + 2] = 0;
        }

        particleGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

        this.particleCircle = new THREE.Points(particleGeometry, particleMaterial);
        this.particleCircle.renderOrder = -1;       // NOTE-240117      -1로 해주면 좀 이뻐보이길래 해둠
        this.particleCircle.visible = false;
        this.group.add(this.particleCircle);
        // //

        // NOTE-240517      Particle Circle의 raycast 영역 그리는 부분
        const fillCircleGeom = new THREE.CircleGeometry(
            this.mainCircleRadius,
            this.mainCircleSegment
        );
        const fillCircleMat = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0,
        });

        this.fillCircle = new THREE.Mesh(fillCircleGeom, fillCircleMat);
        this.fillCircle.visible = false;
        this.summitRaycastCallback(this.fillCircle);
        this.group.add(this.fillCircle);
        // //
    }

    update() {
        this.event.trigger(this.event.UI_MENUBUTTON_MOVED, ['about',
            this.group.localToWorld(this.buttonPosObj.position.clone())]);
    }

    showContent(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const opacityObj = {
            particleOpacity: 0
        };

        if (time <= 0) {
            this.fillCircle.layers.enable(this.layer.raycastTarget);
            this.particleCircle.visible = true;
            this.particleCircle.material.opacity = this.initParticleCircleOpacity;
        }
        else {
            this.contentAnim = gsap.to(opacityObj,
                {
                    particleOpacity: this.initParticleCircleOpacity,
                    duration: time,
                    ease: this.defaultEase,
                    onStart: () => {
                        this.fillCircle.layers.enable(this.layer.raycastTarget);
                        this.particleCircle.visible = true;
                    },
                    onUpdate: () => {
                        this.particleCircle.material.opacity = opacityObj.particleOpacity;
                    }
                }
            );
        }
    }

    hideContent(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const opacityObj = {
            particleOpacity: this.initParticleCircleOpacity
        };

        if (time <= 0) {
            this.fillCircle.layers.disable(this.layer.raycastTarget);
            this.particleCircle.material.opacity = 0;
            this.particleCircle.visible = false;
        }
        else {
            this.contentAnim = gsap.to(opacityObj,
                {
                    particleOpacity: 0,
                    duration: time,
                    ease: this.defaultEase,
                    onUpdate: () => {
                        this.fillCircle.layers.disable(this.layer.raycastTarget);
                        this.particleCircle.material.opacity = opacityObj.particleOpacity;
                    },
                    onComplete: () => {
                        this.particleCircle.visible = false;
                    }
                }
            );
        }
    }

    adjustMenuButtonPos() {
        const newButtonPos = this.group.worldToLocal(this.buttonPosObj.position.clone());
        this.buttonPosObj.position.set(newButtonPos.x, newButtonPos.y, newButtonPos.z);
    }

    summitRaycastCallback(target) {
        target.onhover = function () {
            this.changeTargetOpacity(this.particleCircle, 1);
        }.bind(this);

        target.onhoverOut = function () {
            this.changeTargetOpacity(this.particleCircle, this.initParticleCircleOpacity);
        }.bind(this);

        target.onclick = function () {
            this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, ['about']);
        }.bind(this);
    }

    changeTargetOpacity(target, targetOpacity) {
        if (this.hoverAnim)
            this.hoverAnim.pause();

        this.hoverAnim = gsap.to(target.material, {
            opacity: targetOpacity,
            duration: 0.2
        });
    }
}