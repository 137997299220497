import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core';
import Event from '../Event';

export default class SphereParticle {
    constructor(_options) {

        this.structure = _options.structure;

        this.core = new Core();
        this.event = new Event();
        this.textureLoader = this.core.textureLoader;

        this.awake();

        this.event.on(this.event.INIT_LOAD_STARTED, () => {
            this.load();
        });
    }

    awake() {
        this.group = new THREE.Group();
        this.structure.structureGroup.add(this.group);

        this.initOpacity = 0.4;
        this.defaultFadeTime = 0.5;
        this.mainCircleRadius = 5;
    }

    load() {
        this.textureLoader.load('./images/Circle.png',
            (texture) => {
                this.particleMap = texture;
                this.draw();
            });
    }

    draw() {
        const particlesGeometry = new THREE.BufferGeometry();
        const count = 1000;
        const radius = this.mainCircleRadius;

        const positions = new Float32Array(count * 3);

        for (let i = 0; i < positions.length; i += 3) {
            const theta = Math.random() * Math.PI * 2;
            const phi = Math.random() * Math.PI;

            const x = radius * Math.sin(theta) * Math.cos(phi);
            const y = radius * Math.sin(theta) * Math.sin(phi);
            const z = radius * Math.cos(theta);

            positions[i] = x;
            positions[i + 1] = y;
            positions[i + 2] = z;
        }

        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        const particlesMaterial = new THREE.PointsMaterial({
            size: 0.05,
            alphaMap: this.particleMap,
            alphaTest: 0,
            sizeAttenuation: true,
            transparent: true,
            opacity: this.initOpacity
        });

        this.particleSphere = new THREE.Points(particlesGeometry, particlesMaterial);
        this.particleSphere.boundingSphere = new THREE.Sphere(new THREE.Vector3(0, 0, 0), radius);
        this.particleSphere.visible = false;
        this.group.add(this.particleSphere);
    }

    showContent(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        if (time <= 0) {
            this.particleSphere.visible = true;
            this.particleSphere.material.opacity = this.initOpacity;
        }
        else {
            this.contentAnim = gsap.to(this.particleSphere.material,
                {
                    opacity: this.initOpacity,
                    duration: time,
                    ease: this.defaultEase,
                    onStart: () => {
                        this.particleSphere.visible = true;
                    }
                }
            );
        }
    }

    hideContent(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        if (time <= 0) {
            this.particleSphere.material.opacity = 0;
            this.particleSphere.visible = false;
        }
        else {
            this.contentAnim = gsap.to(this.particleSphere.material,
                {
                    opacity: 0,
                    duration: time,
                    ease: this.defaultEase,
                    onComplete: () => {
                        this.particleSphere.visible = false;
                    }
                }
            );
        }
    }
}