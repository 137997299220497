import * as THREE from 'three';

import Event from '../Event.js';


export default class TypingLoopText {
    constructor(_options) {
        this.event = new Event();

        this.awake();

        this.event.on(this.event.KV_CLOSED, () => {
            this.show();
        });
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED, () => {
            this.hide();
        });
        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED_END, () => {
            this.show();
        });
        this.event.on(this.event.UI_MENUBUTTON_CLICKED, () => {
            this.hide();
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED_END, () => {
            this.show();
        });
        this.event.on(this.event.PROJECTITEM_HOVERED, () => {
            this.hide();
        });
        this.event.on(this.event.PROJECTITEM_HOVEROUTED, () => {
            this.show();
        });
    }

    awake() {
        this.typingText = document.getElementById('typingText-rightBottom');

        this.runUpdate = false;
        this.canLoop = true;

        this.clock = new THREE.Clock();
        this.previousTime = 0;
        this.currentTime = 0;
        this.currentIntervalTime = 0;
        this.currentTextIndex = 1;
        this.currentSubstringIndex = 0;
        this.changeTextTime = 4;
        this.calledOnChangeText = false;

        this.textContent = '';
        this.typeInterval = 0.05;

        this.targetTexts = [
            '13 YEARS EXPERIENCE',
            'MORE THAN 500 PROJECTS',
            'WEB, APPLICATION, MOVIE, NEW MEDIA',
            'INFO@NINEFIVE.COM'
        ];
    }

    update() {
        this.onUpdate();
    }

    onUpdate() {
        if (this.runUpdate === false)
            return;

        const elapsedTime = this.clock.getElapsedTime();
        this.deltaTime = elapsedTime - this.previousTime;
        this.previousTime = elapsedTime;

        if (this.canLoop)
            this.currentTime += this.deltaTime;

        if (this.currentTime > this.changeTextTime) {
            if (this.calledOnChangeText === false) {
                this.textContent = '';
                this.typingText.textContent = this.textContent;
                this.calledOnChangeText = true;
            }

            this.currentIntervalTime += this.deltaTime;
            if (this.currentIntervalTime > this.typeInterval) {
                this.currentIntervalTime = 0;
                const targetText = this.targetTexts[this.currentTextIndex];
                this.textContent += targetText.substring(this.currentSubstringIndex, this.currentSubstringIndex + 1);
                this.typingText.textContent = this.textContent;
                ++this.currentSubstringIndex;
                if (this.currentSubstringIndex > targetText.length - 1) {
                    this.currentTime = 0;
                    this.currentIntervalTime = 0;
                    this.currentSubstringIndex = 0;
                    this.currentTextIndex = (this.currentTextIndex + 1) % this.targetTexts.length;
                    this.calledOnChangeText = false;
                }
            }
        }
    }

    hide() {
        this.runUpdate = false;
        this.typingText.classList.remove('show');
    }

    show() {
        this.runUpdate = true;
        this.typingText.classList.add('show');
    }
}