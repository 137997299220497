import * as THREE from 'three';
import Event from '../Event';


export default class MenuLineHandler {
    constructor(_options) {
        this.event = new Event();

        this.event.on(this.event.UI_MENULINE_HOVERED, (key) => {
            this.onHoverWithKey(key);
        });
        this.event.on(this.event.UI_MENULINE_HOVEROUTED, (key) => {
            this.onHoverOutWithKey(key);
        });

        this.awake();
    }

    awake() {
        this.lines = document.getElementsByClassName('menuUI-line');
    }

    onHoverWithKey(key) {
        for (let i = 0; i < this.lines.length; ++i) {
            const thisKey = this.lines[i].getAttribute('key');
            if (thisKey === key) {
                this.lines[i].classList.add('hover');
                break;
            }
        }
    }

    onHoverOutWithKey(key) {
        for (let i = 0; i < this.lines.length; ++i) {
            const thisKey = this.lines[i].getAttribute('key');
            if (thisKey === key) {
                this.lines[i].classList.remove('hover');
                break;
            }
        }
    }
} 