import Core from "./Core";
import Event from "./Event";


export default class DataRequestController {
    constructor(_options) {
        this.event = new Event();

        this.awake();
    }

    awake() {
        this.initLanguage = 'KOREAN';
        this.projectListData = undefined;
        this.sendProjectListRequest(this.initLanguage, (response) => this.onReceived(response));
    }

    onReceived(response) {
        console.log('project list data received');

        this.projectListData = response.data;
        this.event.trigger(this.event.PROJECT_DATA_RECEIVED, [this.projectListData]);
    }

    sendProjectListRequest(lang, onReceived) {
        let url = `https://${window.location.host}/api/v1/main/findAll/${lang}`;
        if (window.location.host.includes('localhost'))
            url = `https://dev.ninefive.org/api/v1/main/findAll/${lang}`;

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                var response = JSON.parse(xhr.responseText);

                if (onReceived != undefined)
                    onReceived(response);
            }
        };
        xhr.send();
    }
}