import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core';
import Event from '../Event';
import MenuButtonHandler from './MenuButtonHandler';
import MenuLineHandler from './MenuLineHandler';
import MenuTextHandler from './MenuTextHandler';


export default class MenuUIController {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.scene = this.core.scene;
        this.structure = this.core.structure;
        this.camera = this.core.camera;
        this.config = this.core.config;

        // NOTE-240221      위치 잡아주는거랑 hover, click 이벤트랑 같은 스크립트에 넣으려니 복잡해서 나눔
        this.buttonHandler = new MenuButtonHandler();
        this.lineHandler = new MenuLineHandler();
        this.textHandler = new MenuTextHandler();
        // //

        this.awake();
    }

    awake() {
        this.group = new THREE.Group();
        this.scene.add(this.group);

        this.menuButtonMargin = 30 + 40 * 0.5;
        this.menuButtonMobileMargin = 15 + 40 * 0.5;

        this.menuButtonLines = document.getElementsByClassName('menuUI-line');
        this.menuButtons = document.getElementsByClassName('menuUI-button');
        this.menuButtonTexts = document.getElementsByClassName('menuUI-text');

        this.event.on(this.event.UI_MENUBUTTON_MOVED, (key, pos) => {
            this.updateMenuUI(key, pos);
        });
        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, () => {
            this.setActiveButtons(true);
            this.setActiveLines(true);
            this.setActiveTexts(true);
        });
    }

    updateMenuUI(key, pos) {
        var screenPos = this.worldToScreenPos(pos);
        var centerPos = new THREE.Vector2(1, 0);
        var screenWidth = this.config.width - this.getCurrentMargin();
        var screenHeight = this.config.height - this.getCurrentMargin();
        var screenPosForAngle = new THREE.Vector2(screenPos.x - screenWidth * 0.5, screenPos.y - screenHeight * 0.5);
        var angleRad = centerPos.clone().angleTo(screenPosForAngle);
        var angleDeg = THREE.MathUtils.radToDeg(angleRad);
        if (screenPos.y > screenHeight * 0.5)
            angleDeg *= -1;

        if (angleDeg < 0)
            angleDeg += 360;

        this.updateMenuButtonPos(key, angleRad, angleDeg);
        this.updateMenuLines(key);
    }

    updateMenuButtonPos(key, angleRad, angleDeg) {
        var screenWidth = this.config.width - this.getCurrentMargin();
        var screenHeight = this.config.height - this.getCurrentMargin();
        var xPos = Math.cos(angleRad) * screenWidth;
        var yPos = Math.sin(angleRad) * screenWidth;
        var divisionAngle = THREE.MathUtils.radToDeg(Math.atan(screenHeight / screenWidth));

        var resultPos = {};
        if (angleDeg < 180 - divisionAngle)
            resultPos = new THREE.Vector2(xPos, -yPos);
        else if (angleDeg < 180)
            resultPos = new THREE.Vector2(-xPos, yPos);
        else if (angleDeg < 180 + divisionAngle)
            resultPos = new THREE.Vector2(-xPos, -yPos);
        else
            resultPos = new THREE.Vector2(xPos, yPos);

        var hypoLength = {};
        if (angleDeg < divisionAngle)
            hypoLength = screenWidth * 0.5 / Math.cos(angleRad);
        else if (angleDeg < 180 - divisionAngle)
            hypoLength = screenHeight * 0.5 / Math.sin(angleRad);
        else if (angleDeg < 180 + divisionAngle)
            hypoLength = screenWidth * 0.5 / Math.cos(angleRad);
        else if (angleDeg < 360 - divisionAngle)
            hypoLength = screenHeight * 0.5 / Math.sin(angleRad);
        else
            hypoLength = screenWidth * 0.5 / Math.cos(angleRad);

        resultPos = resultPos.normalize().multiplyScalar(hypoLength);
        resultPos.add(new THREE.Vector2(screenWidth * 0.5, screenHeight * 0.5));
        resultPos.x = Math.max(resultPos.x, this.getCurrentMargin());
        resultPos.y = Math.max(resultPos.y, this.getCurrentMargin());

        var reverseResultPos = resultPos.clone();
        reverseResultPos.x = this.config.width - reverseResultPos.x;
        reverseResultPos.y = this.config.height - reverseResultPos.y;

        for (let i = 0; i < this.menuButtons.length; ++i) {
            const name = this.menuButtons[i].getAttribute('key');
            if (name !== key)
                continue;

            if (name === 'contact') {
                this.menuButtons[i].style.left = `${resultPos.x}px`;
                this.menuButtons[i].style.top = `${resultPos.y}px`;
            }
            else if (name === 'about') {
                this.menuButtons[i].style.left = `${reverseResultPos.x}px`;
                this.menuButtons[i].style.top = `${reverseResultPos.y}px`;
            }
            else if (name === 'expertise') {
                this.menuButtons[i].style.left = `${reverseResultPos.x}px`;
                this.menuButtons[i].style.top = `${reverseResultPos.y}px`;
            }
        }

        for (let i = 0; i < this.menuButtonTexts.length; ++i) {
            const name = this.menuButtonTexts[i].getAttribute('key');
            if (name !== key)
                continue;

            if (name === 'contact') {
                this.menuButtonTexts[i].style.left = `${reverseResultPos.x}px`;
                this.menuButtonTexts[i].style.top = `${reverseResultPos.y}px`;
            }
            else if (name === 'about') {
                this.menuButtonTexts[i].style.left = `${resultPos.x}px`;
                this.menuButtonTexts[i].style.top = `${resultPos.y}px`;
            }
            else if (name === 'expertise') {
                this.menuButtonTexts[i].style.left = `${resultPos.x}px`;
                this.menuButtonTexts[i].style.top = `${resultPos.y}px`;
            }
        }
    }

    updateMenuLines(key) {
        for (let i = 0; i < this.menuButtonLines.length; ++i) {
            const name = this.menuButtonLines[i].getAttribute('key');
            if (name !== key)
                continue;

            var buttonPos = new THREE.Vector2(
                parseFloat(this.menuButtons[i].style.left.replace('px', '')),
                parseFloat(this.menuButtons[i].style.top.replace('px', ''))
            );
            var tempSize = new THREE.Vector2(this.config.width * 0.5, this.config.height * 0.5);
            var baseVector = new THREE.Vector2(1, 0);

            buttonPos.add(tempSize.clone().multiplyScalar(-1));

            var lineAngle = THREE.MathUtils.radToDeg(baseVector.clone().angleTo(buttonPos.clone()));
            if (buttonPos.y < 0)
                lineAngle *= -1;
            this.menuButtonLines[i].style.transform = `translate(-50%, -50%) rotate(${lineAngle}deg)`;
        }
    }

    setActiveButtons(value) {
        for (let i = 0; i < this.menuButtons.length; ++i) {
            if (value)
                this.menuButtons[i].classList.add('show');
            else
                this.menuButtons[i].classList.remove('show');
        }
    }

    setActiveLines(value) {
        for (let i = 0; i < this.menuButtonLines.length; ++i) {
            if (value)
                this.menuButtonLines[i].classList.add('show');
            else
                this.menuButtonLines[i].classList.remove('show');
        }
    }

    setActiveTexts(value) {
        for (let i = 0; i < this.menuButtonTexts.length; ++i) {
            if (value)
                this.menuButtonTexts[i].classList.add('show');
            else
                this.menuButtonTexts[i].classList.remove('show');
        }
    }

    worldToScreenPos(position) {
        const vector = position.clone();
        vector.project(this.camera.instance);

        const widthHalf = 0.5 * (this.config.width - this.getCurrentMargin());
        const heightHalf = 0.5 * (this.config.height - this.getCurrentMargin());

        vector.x = (vector.x * widthHalf) + widthHalf;
        vector.y = -(vector.y * heightHalf) + heightHalf;

        return {
            x: vector.x,
            y: vector.y,
        };
    }

    getCurrentMargin() {
        if (this.config.isMobile)
            return this.menuButtonMobileMargin;
        else
            return this.menuButtonMargin;
    }
}