import * as THREE from 'three';
import { gsap } from 'gsap';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import Core from "./Core.js";
import Event from './Event.js';


export default class Camera {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.config = this.core.config;
        this.targetElement = this.core.targetElement;
        this.scene = this.core.scene;
        this.cssScene = this.core.cssScene;
        this.debug = this.core.debug;

        this.awake();
        this.setInstance();
        this.setOrbitControls();
        this.setDebug();

        this.event.on(this.event.TRIGGER_RESPONSIVE, (isMobile) => {
            if (isMobile)
                this.moveCamera(this.perspCameraInfo.mobilePos, 0.5);
            else
                this.moveCamera(this.perspCameraInfo.pos, 0.5);
        });





        // window.addEventListener('keydown', (event) => {
        //     if (event.key === 'm') {
        //         const worldPos = this.instance.position.clone();
        //         console.log(`카메라 월드 Pos: \n${worldPos.x}\n${worldPos.y}\n${worldPos.z}`);
        //     }
        //     if (event.key === '.') {
        //         console.log(`카메라 OrbitTarget: \n${this.orbitControls.target.x}\n${this.orbitControls.target.y}\n${this.orbitControls.target.z}`);
        //     }
        //     if (event.key === '/') {
        //         console.log(`카메라 Zoom: \n${this.orbitControls.getDistance()}`);
        //     }
        // });

    }

    awake() {
        this.orthoCameraInfo = {
            fov: 5,
            pos: new THREE.Vector3(0, 0, 300),
            mobilePos: new THREE.Vector3(0, 0, 420)
        };
        this.perspCameraInfo = {
            fov: 70,
            pos: new THREE.Vector3(0, 0, 18.71),
            mobilePos: new THREE.Vector3(0, 0, 26.18)
        };
        this.isOrtho = true;
        this.currentFov = this.orthoCameraInfo.fov;
        this.initPosition = this.config.isMobile ? this.orthoCameraInfo.mobilePos : this.orthoCameraInfo.pos;
        this.currentDistance = 0;

        this.minDistance = 10;
        this.maxDistance = 30;
        this.minDistanceMobile = 20;
        this.maxDistanceMobile = 40;
    }

    update() {
        this.orbitControls.update();
        this.currentDistance = this.orbitControls.getDistance();
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(this.currentFov, this.config.width / this.config.height);
        this.instance.position.set(
            this.initPosition.x,
            this.initPosition.y,
            this.initPosition.z
        );
        this.instance.lookAt(0, 0, 0);
        this.scene.add(this.instance);

        this.cssCamera = new THREE.OrthographicCamera(
            -this.config.width * 0.5,
            this.config.width * 0.5,
            this.config.height * 0.5,
            -this.config.height * 0.5,
            0.1,
            100
        );
        this.cssCamera.updateProjectionMatrix();
        this.cssScene.add(this.cssCamera);
    }

    setOrbitControls() {
        this.orbitControls = new OrbitControls(this.instance, this.targetElement);
        this.orbitControls.enablePan = false;
        this.orbitControls.enableRotate = false;
        this.orbitControls.enableDamping = false;
        this.orbitControls.enableZoom = true;
        this.orbitControls.enabled = false;
    }

    resize() {
        this.instance.aspect = this.config.width / this.config.height;
        this.instance.updateProjectionMatrix();

        this.cssCamera.left = -this.config.width * 0.5;
        this.cssCamera.right = this.config.width * 0.5;
        this.cssCamera.top = this.config.height * 0.5;
        this.cssCamera.bottom = -this.config.height * 0.5;
        this.cssCamera.updateProjectionMatrix();
    }

    resetTransform() {
        this.instance.position.copy(this.initPosition);
        this.instance.lookAt(0, 0, 0);
    }

    switchCameraMode(isOrtho, time) {
        if (this.isOrtho === isOrtho)
            return;

        if (this.switchCameraModeAnim)
            this.switchCameraModeAnim.pause();

        const minDist = this.config.isMobile ? this.minDistanceMobile : this.minDistance;
        const maxDist = this.config.isMobile ? this.maxDistanceMobile : this.maxDistance;
        this.switchCameraModeAnim = gsap.fromTo(this,
            {
                currentFov: this.instance.fov
            },
            {
                currentFov: isOrtho ? this.orthoCameraInfo.fov : this.perspCameraInfo.fov,
                duration: time,
                ease: 'power4.out',
                onStart: () => {
                    this.isOrtho = isOrtho;
                    this.orbitControls.enabled = false;
                    this.orbitControls.minDistance = -Infinity;
                    this.orbitControls.maxDistance = Infinity;
                },
                onUpdate: () => {
                    this.setFov(this.currentFov);
                },
                onComplete: () => {
                    this.orbitControls.enabled = true;
                    this.orbitControls.minDistance = isOrtho ? -Infinity : minDist;
                    this.orbitControls.maxDistance = isOrtho ? Infinity : maxDist;
                }
            }
        );
    }

    quickSwitchCameraMode(isOrtho) {
        if (this.isOrtho === isOrtho)
            return;

        if (this.switchCameraModeAnim)
            this.switchCameraModeAnim.pause();

        this.currentFov = isOrtho ? this.orthoCameraInfo.fov : this.perspCameraInfo.fov;
        const minDist = this.config.isMobile ? this.minDistanceMobile : this.minDistance;
        const maxDist = this.config.isMobile ? this.maxDistanceMobile : this.maxDistance;
        this.isOrtho = isOrtho;
        this.orbitControls.enabled = false;
        this.orbitControls.minDistance = -Infinity;
        this.orbitControls.maxDistance = Infinity;
        this.setFov(this.currentFov);
        this.orbitControls.enabled = true;
        this.orbitControls.minDistance = isOrtho ? -Infinity : minDist;
        this.orbitControls.maxDistance = isOrtho ? Infinity : maxDist;
    }

    setFov(newFov) {
        if (newFov <= 0)
            return;

        const currentFOV = this.instance.fov;
        const distChangeRatio = Math.tan((currentFOV / 2) * (Math.PI / 180)) / Math.tan((newFov / 2) * (Math.PI / 180));
        const newPosition = this.instance.position.clone().multiplyScalar(distChangeRatio);

        this.instance.fov = newFov;
        this.instance.position.set(newPosition.x, newPosition.y, newPosition.z);
        this.instance.updateProjectionMatrix();
    }

    resetToPerspective(time) {
        if (this.resetAnim)
            this.resetAnim.pause();

        if (time <= 0) {
            if (this.config.isMobile)
                this.instance.position.copy(this.perspCameraInfo.mobilePos);
            else
                this.instance.position.copy(this.perspCameraInfo.pos);
        }
        else {
            this.resetAnim = gsap.to(this.instance.position, {
                x: this.config.isMobile ? this.perspCameraInfo.mobilePos.x : this.perspCameraInfo.pos.x,
                y: this.config.isMobile ? this.perspCameraInfo.mobilePos.y : this.perspCameraInfo.pos.y,
                z: this.config.isMobile ? this.perspCameraInfo.mobilePos.z : this.perspCameraInfo.pos.z,
                duration: time
            });
        }
    }

    moveCamera(pos, time) {
        if (this.moveAnim)
            this.moveAnim.pause();

        this.moveAnim = gsap.to(this.instance.position, {
            x: pos.x,
            y: pos.y,
            z: pos.z,
            duration: time
        });
    }









    setDebug() {
        const cameraDebug = this.debug.addFolder('Camera');

        cameraDebug
            .add(this.orbitControls, 'enabled')
            .listen();
    }
}