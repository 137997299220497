import * as THREE from 'three';
import gsap from 'gsap';


import { MeshLine, MeshLineMaterial } from 'three.meshline';
import GeometryUtils from '../GeometryUtils';


export default class PanelBase {
    constructor(_options) {
        this.geometryUtils = new GeometryUtils();
        this.structure = _options.structure;

        this.baseAwake();
        this.drawOutlineCircle();
    }

    baseAwake() {
        this.group = new THREE.Group();
        this.structure.structureGroup.add(this.group);

        this.defaultLineOpacity = 0.5;
        this.fadeStateLineOpacity = 0.1;
        this.mainCircleWidth = 0.015;
        this.mainCircleRadius = 5;
        this.mainCircleSegment = 128;
        this.defaultFadeTime = 0.3;
        this.defaultEase = 'power1.in';

        // NOTE-240415      메뉴 보여지는 상황에서 배경으로 보여지는 경우 선은 옅게 하기 위함
        this.isFadeState = false;
    }

    drawOutlineCircle() {
        const circleGeom = this.geometryUtils.getCircleGeometry(
            this.mainCircleRadius,
            this.mainCircleSegment
        );
        const circleMeshLine = new MeshLine();
        circleMeshLine.setGeometry(circleGeom);

        const circleMat = new MeshLineMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: this.isFadeState ? this.fadeStateLineOpacity : this.defaultLineOpacity,
            lineWidth: this.mainCircleWidth
        });
        this.circle = new THREE.Mesh(circleMeshLine, circleMat);
        this.circle.visible = false;
        this.group.add(this.circle);
    }

    showOutline(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.outlineAnim)
            this.outlineAnim.pause();

        if (time <= 0) {
            this.circle.material.opacity = this.isFadeState ? this.fadeStateLineOpacity : this.defaultLineOpacity;
            this.circle.visible = true;
        }
        else {
            this.outlineAnim = gsap.to(this.circle.material,
                {
                    opacity: this.isFadeState ? this.fadeStateLineOpacity : this.defaultLineOpacity,
                    duration: time,
                    onStart: () => {
                        this.circle.visible = true;
                    }
                }
            );
        }
    }

    hideOutline(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.outlineAnim)
            this.outlineAnim.pause();

        if (time <= 0) {
            this.circle.material.opacity = 0;
            this.circle.visible = false;
        }
        else {
            this.outlineAnim = gsap.to(this.circle.material,
                {
                    opacity: 0,
                    duration: time,
                    onComplete: () => {
                        this.circle.visible = false;
                    }
                }
            );
        }
    }
}