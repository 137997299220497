import * as THREE from 'three';
import gsap from 'gsap';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

import Core from './Core.js';
import Event from './Event.js';


export default class Renderer {
    constructor(_options) {
        this.event = new Event();
        this.core = new Core();
        this.config = this.core.config;
        this.scene = this.core.scene;
        this.cssScene = this.core.cssScene;
        this.camera = this.core.camera;
        this.cssCamera = this.core.camera.cssCamera;
        this.debug = this.core.debug;

        this.awake();

        this.event.on(this.event.REQUEST_ENABLE_CSSRENDERER, () => {
            this.enableCSS(this.cssAnimTime);
        });
        this.event.on(this.event.REQUEST_DISABLE_CSSRENDERER, () => {
            this.disableCSS(this.cssAnimTime);
        });
    }

    awake() {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.core.targetElement,
            antialias: true
        });

        this.instance.setSize(this.config.width, this.config.height);
        this.instance.setPixelRatio(this.config.pixelRatio);

        this.cssRenderer = new CSS3DRenderer({
            antialias: true
        });
        this.cssRenderer.setSize(this.config.width, this.config.height);
        document.body.appendChild(this.cssRenderer.domElement);
        this.cssRenderer.domElement.style.display = 'none';
        this.cssRenderer.domElement.style.opacity = 0;

        this.cssAnimTime = 0.65;
    }

    resize() {
        this.instance.setSize(this.config.width, this.config.height);
        this.instance.setPixelRatio(this.config.pixelRatio);

        this.cssRenderer.setSize(this.config.width, this.config.height);
    }

    update() {
        if (this.instance)
            this.instance.render(this.scene, this.camera.instance);

        if (this.cssRenderer)
            this.cssRenderer.render(this.cssScene, this.cssCamera);
    }

    enableCSS(time) {
        if (this.setActiveAnim)
            this.setActiveAnim.pause();

        const animObj = { opacity: 0 };
        this.setActiveAnim = gsap.to(animObj, {
            opacity: 1,
            duration: time,
            onStart: () => {
                this.cssRenderer.domElement.style.display = 'block';
            },
            onUpdate: () => {
                this.cssRenderer.domElement.style.opacity = animObj.opacity;
            }
        });
    }

    disableCSS(time) {
        if (this.setActiveAnim)
            this.setActiveAnim.pause();

        const animObj = { opacity: 1 };
        this.setActiveAnim = gsap.to(animObj, {
            opacity: 0,
            duration: time,
            onUpdate: () => {
                this.cssRenderer.domElement.style.opacity = animObj.opacity;
            },
            onComplete: () => {
                this.cssRenderer.domElement.style.display = 'none';
                this.event.trigger(this.event.TRIGGER_CSSRENDERER_DISABLED);
            }
        });
    }
}