import * as THREE from 'three';

import Core from './Core.js';


export default class Light {
    constructor(_options) {
        this.core = new Core();
        this.scene = this.core.scene;

        this.initialize();
    }

    initialize() {
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        this.scene.add(this.directionalLight);

        this.ambientLight = new THREE.AmbientLight(0xffffff, 1);
        this.scene.add(this.ambientLight);
    }
}