import * as THREE from 'three';

import Core from '../Core.js';
import LogoHeader from './LogoHeader.js';
import MenuUIController from './MenuUIController.js';
import IframeController from './IframeController.js';
import TypingLoopText from './TypingLoopText.js';
import IframeProjectDetail from './IframeProjectDetail.js';
import IframeMenuUI2D from './IframeMenuUI2D.js';
import KvController from './KvController.js';
import BgmController from './BgmController.js';


export default class Dom {
    constructor(_options) {
        this.core = new Core();

        this.logoHeader = new LogoHeader();
        this.menuButtonController = new MenuUIController();
        this.typingLoopText = new TypingLoopText();
        this.iframeController = new IframeController();
        this.iframeProjectDetail = new IframeProjectDetail();
        this.iframeMenuUI2D = new IframeMenuUI2D();
        this.kvController = new KvController();
        this.bgmController = new BgmController();
    }

    update() {
        if (this.iframeController)
            this.iframeController.update();

        if (this.kvController)
            this.kvController.update();

        if (this.typingLoopText)
            this.typingLoopText.update();
    }

    resize() {
        if (this.iframeController)
            this.iframeController.resize();

        if (this.kvController)
            this.kvController.resize();
    }
}