import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core.js';
import Event from '../Event.js';


export default class ExpertiseVideoCircle {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.structure = _options.structure;
        this.textureLoader = this.core.textureLoader;

        this.awake(_options);

        this.event.on(this.event.INIT_LOAD_STARTED, () => {
            this.load(_options);
        });
    }

    awake(_options) {
        this.circleRadius = 5;
        this.circleSegment = 128;

        this.key = _options.key;
        this.initPos = _options.initPos;
        this.circleRadius = _options.radius;
        this.bigCircleRadius = _options.bigRadius;
        this.initOpacity = _options.initOpacity;
    }

    load(_options) {
        this.textureLoader.load(_options.filePath,
            (texture) => {
                this.thumbnailTexture = texture;
                this.thumbnailTexture.colorSpace = THREE.SRGBColorSpace;

                const geom = new THREE.CircleGeometry(this.circleRadius, this.circleSegment);
                const mat = new THREE.MeshBasicMaterial({
                    side: THREE.DoubleSide,
                    transparent: true,
                    opacity: this.initOpacity,
                    map: this.thumbnailTexture
                });

                this.circle = new THREE.Mesh(geom, mat);
                this.circle.position.set(this.initPos.x, this.initPos.y, this.initPos.z);

                _options.onload(this.circle);
            }
        );
    }
}