import * as THREE from 'three';

import Core from '../Core';
import Event from "../Event";


export default class IframeMenuUI2D {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.awake();

        this.event.on(this.event.REQUEST_PROJECTLIST_IFRAME_ENABLE, () => {
            this.enableIframe();
        });
        this.event.on(this.event.REQUEST_PROJECTLIST_IFRAME_DISABLE, () => {
            this.disableIframe();
        });

        // NOTE-240604      iframe 내부 메뉴가 클릭되었을 때
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.iframe.src = this.aboutSrc;
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.iframe.src = this.contactSrc;
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.iframe.src = this.expertiseSrc;
        });
        this.event.on(this.event.REQUEST_CHANGE_IFRAME_URL, (url) => {
            this.iframe.src = url;
        });
        // //
    }

    awake() {
        this.iframe = document.getElementById('menuUI2D-iframe');
        this.iframeContainer = document.getElementById('menuUI2D-iframe-container');

        this.projectListSrc = this.core.urlContainer.projectListUrl;
        this.aboutSrc = this.core.urlContainer.aboutUrl;
        this.contactSrc = this.core.urlContainer.contactUrl;
        this.expertiseSrc = this.core.urlContainer.expertiseUrl;
    }

    enableIframe() {
        this.iframeContainer.classList.add('show');
        this.iframe.classList.add('show');
        this.iframe.src = this.projectListSrc;
    }

    disableIframe() {
        this.iframeContainer.classList.remove('show');
        this.iframe.classList.remove('show');
        setTimeout(() => {
            this.iframe.src = '';
        }, 500);
    }
}