import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core.js';
import PanelBase from './PanelBase.js';
import { MeshLine, MeshLineMaterial } from 'three.meshline';


// NOTE-240221      90도 돌아가있는 원. 아무것도 안담고 있음. 이게 Project?
export default class EmptyPanel extends PanelBase {
    constructor(_options) {
        super(_options);

        this.core = new Core();
        this.structure = _options.structure;

        this.initialize();
    }

    initialize() {
        this.group.rotation.y = THREE.MathUtils.degToRad(90);
    }

    update() {
    }
}