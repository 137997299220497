import Core from './Core';
import Event from './Event';

export default class StateController {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.awake();

        // NOTE-240702      이벤트 Wrapper------------------------------------------

        // NOTE-240702      메뉴 버튼 관련
        this.event.on(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, (key, isQuick) => {
            this.currentMenu = `MENU_${key}`;
            this.event.trigger(this.event.UI_MENUBUTTON_CLICKED, [key, isQuick]);
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED_WRAPPER, () => {
            if (this.currentMenu == undefined) {
                this.event.trigger(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE);
            }
            else {
                const key = this.currentMenu.replace('MENU_', '');
                this.event.trigger(this.event.UI_MENUBACKBUTTON_CLICKED, [key]);
                this.currentMenu = undefined;
            }
        });
        // //

        // NOTE-240702      리스트뷰 관련
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED_WRAPPER, () => {
            if (this.currentMenu == undefined) {
                this.currentMenu = this.LISTVIEW;
                this.event.trigger(this.event.UI_SWITCHBUTTON_CLICKED);
            }
            else if (this.currentMenu.includes('MENU2D')) {
                this.currentMenu = this.LISTVIEW;
                this.event.trigger(this.event.REQUEST_CHANGE_IFRAME_URL, [this.core.urlContainer.projectListUrl]);
                this.event.trigger(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU2D);
            }
            else if (this.currentMenu.includes('MENU')) {
                this.currentMenu = this.LISTVIEW;
                this.event.trigger(this.event.REQUEST_DISABLE_CSSRENDERER);
                this.event.trigger(this.event.REQUEST_PROJECTLIST_IFRAME_ENABLE);
                this.event.trigger(this.event.UI_SWITCHBUTTON_CLICKED_FROM_MENU);
            }
        });
        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED_WRAPPER, () => {
            this.currentMenu = undefined;
            this.event.trigger(this.event.UI_SWITCHBACKBUTTON_CLICKED);
        });
        // //

        // NOTE-240703      리스트뷰를 타고 메뉴를 눌렀을 때
        this.event.on(this.event.MENU2D_ABOUT_CLICKED, () => {
            this.currentMenu = 'MENU2D_ABOUT';
        });
        this.event.on(this.event.MENU2D_EXPERTISE_CLICKED, () => {
            this.currentMenu = 'MENU2D_EXPERTISE';
        });
        this.event.on(this.event.MENU2D_CONTACT_CLICKED, () => {
            this.currentMenu = 'MENU2D_CONTACT';
        });
        this.event.on(this.event.MENU2D_PROJECT_CLICKED, () => {
            this.currentMenu = 'MENU2D_PROJECT';
        });
        // //

        //--------------------------------------------------------------------------//
    }

    awake() {
        this.LISTVIEW = 'LISTVIEW';

        this.currentMenu = undefined;
    }
}