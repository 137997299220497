import * as THREE from 'three';

import Core from './Core';
import Event from './Event';


export default class ResponsiveHandler {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.config = this.core.config;

        this.awake();
    }

    awake() {
        this.previousWidth = this.config.width;
        this.previousHeight = this.config.height;
        this.currentWidth = this.config.width;
        this.currentHeight = this.config.height;
    }

    resize() {
        this.currentWidth = this.config.width;
        this.currentHeight = this.config.height;

        if (this.previousWidth > 768 && this.currentWidth <= 768) {
            console.log(`responsive for mobile`);
            this.event.trigger(this.event.TRIGGER_RESPONSIVE, [true]);
        }
        else if (this.previousWidth <= 768 && this.currentWidth > 768) {
            console.log(`responsive for pc`);
            this.event.trigger(this.event.TRIGGER_RESPONSIVE, [false]);
        }

        this.previousWidth = this.currentWidth;
        this.previousHeight = this.currentHeight;
    }
}