import * as THREE from 'three';
import Stats from 'stats.js';
import { GUI } from 'lil-gui';

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';

import Camera from './Camera.js';
import Light from './Light.js';
import Renderer from './Renderer.js';
import Raycaster from './Raycaster.js';
import Structure from './Structure/Structure.js';
import TypingIntro from './Intro/TypingIntro.js';
import Dom from './Dom/Dom.js';
import BackgroundParticle from './BackgroundParticle.js';
import ResponsiveHandler from './ResponsiveHandler.js';
import DataRequestController from './DataRequestController.js';
import Event from './Event.js';
import URLContainer from './URLContainer.js';
import MessageHandler from './MessageHandler.js';
import HistoryBack from './HistoryBack.js';
import HrefHandler from './HrefHandler.js';
import StateController from './StateController.js';



export default class Core {
    static instance;

    constructor(_options) {
        if (Core.instance)
            return Core.instance;

        Core.instance = this;
        this.event = new Event();
        this.targetElement = _options.targetElement;

        // NOTE-240221      부수적인거(독립적) 초기화
        this.jumpToProject = false;
        this.jumpToMenu = false;
        this.projectDataList = undefined;

        this.setConfig();
        this.setEtc();
        this.setDebug();
        this.setLoader();
        this.setLayer();
        // //

        // NOTE-240221      Environment 초기화
        this.setScene();
        this.setCamera();
        this.setRenderer();
        this.setLight();
        this.setBackgroundParticle();
        // //

        this.setRaycaster();
        this.setIntro();
        this.setStructure();
        this.setDom();

        this.update();

        this.event.on(this.event.URL_CHECK_FINISHED, (params) => {
            if (this.jumpToProject === false && this.jumpToMenu === false) {
                // NOTE-240531      굳이 기다리는 이유는 첫 글자부터 나오는걸 제대로 보게 하기 위해 -> 근데 의미가 없나..?
                setTimeout(() => {
                    this.event.trigger(this.event.INTRO_STARTED);
                }, 100);
            }
        });

        this.event.on(this.event.INIT_LOAD_FINISHED, () => {
            if (this.jumpToProject || this.jumpToMenu) {
                this.event.trigger(this.event.INTRO_FINISHED, [true]);
            }
        });

        this.event.on(this.event.PROJECT_DATA_RECEIVED, (projectList) => {
            this.projectDataList = projectList;
            this.checkURL(projectList);
            this.callFirstLoad();
        });

        this.event.on(this.event.PROJECTITEM_CLICKED_WRAPPER, (title) => {
            const res = this.findProjectUrl(title);
            if (res.url != undefined) {
                this.event.trigger(this.event.PROJECTITEM_CLICKED, [{
                    title: res.title,
                    url: res.url
                }]);
            }
        });

        window.addEventListener('resize', () => this.resize());
    }

    callFirstLoad() {
        this.loadingManager.onProgress = this.onFirstLoadProgress.bind(this);
        this.loadingManager.onLoad = this.onFirstLoaded.bind(this);
        this.event.trigger(this.event.INIT_LOAD_STARTED);
    }

    onFirstLoadProgress(itemUrl, itemsLoaded, itemsTotal) {
        const progressRatio = itemsLoaded / itemsTotal;
        this.event.trigger(this.event.INIT_LOAD_PROGRESS, [progressRatio]);
    }

    onFirstLoaded() {
        console.log('All preset load finished');

        this.event.trigger(this.event.INIT_LOAD_FINISHED);
        this.loadingManager.onProgress = undefined;
        this.loadingManager.onLoad = undefined;
    }

    update() {
        const elapsedTime = this.clock.getElapsedTime();
        this.deltaTime = elapsedTime - this.previousTime;
        this.previousTime = elapsedTime;

        if (this.config.debug && this.stats)
            this.stats.begin();

        if (this.camera)
            this.camera.update();

        if (this.renderer)
            this.renderer.update();

        if (this.dom)
            this.dom.update();

        if (this.structure)
            this.structure.update();

        if (this.backgroundParticle)
            this.backgroundParticle.update();

        if (this.intro)
            this.intro.update();

        if (this.raycaster)
            this.raycaster.update();

        window.requestAnimationFrame(() => {
            this.update();
        });

        if (this.config.debug && this.stats)
            this.stats.end();
    }

    setEtc() {
        this.clock = new THREE.Clock();
        this.deltaTime = 0;
        this.previousTime = 0;

        this.responsiveHandler = new ResponsiveHandler();
        this.dataRequestController = new DataRequestController();
        this.urlContainer = new URLContainer();
        this.messageHandler = new MessageHandler();
        this.historyBack = new HistoryBack();
        this.hrefHandler = new HrefHandler();
        this.stateController = new StateController();
    }

    setConfig() {
        this.config = {};

        this.config.debug = window.location.hash.includes('#debug');

        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);
        this.config.width = window.innerWidth;
        this.config.height = window.innerHeight;
        this.config.isMobileDevice = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
        this.config.isMobile = this.config.width <= 768;
    }

    setLayer() {
        this.layer = {};
        this.layer.default = 0;
        this.layer.raycastTarget = 1;
    }

    setDebug() {
        this.debug = new GUI({
            width: 400,
            title: 'Debug Panel'
        });

        this.debug.domElement.style.display = this.config.debug ? 'block' : 'none';

        this.stats = new Stats();
        if (this.config.debug) {
            this.stats.showPanel(0);
            document.body.appendChild(this.stats.domElement);
        }
    }

    setScene() {
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x1C1C1C);

        this.cssScene = new THREE.Scene();
    }

    setCamera() {
        this.camera = new Camera();
    }

    setRenderer() {
        this.renderer = new Renderer();
    }

    setLight() {
        this.light = new Light();
    }

    setBackgroundParticle() {
        this.backgroundParticle = new BackgroundParticle();
    }

    setLoader() {
        this.loadingManager = new THREE.LoadingManager();
        this.textureLoader = new THREE.TextureLoader(this.loadingManager);
        this.fontLoader = new FontLoader(this.loadingManager);
    }

    setStructure() {
        this.structure = new Structure();
    }

    setDom() {
        this.dom = new Dom();
    }

    setIntro() {
        this.intro = new TypingIntro();
    }

    setRaycaster() {
        this.raycaster = new Raycaster();
    }

    resize() {
        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2);
        this.config.width = window.innerWidth;
        this.config.height = window.innerHeight;
        this.config.isMobileDevice = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
        this.config.isMobile = this.config.width <= 768;

        if (this.camera)
            this.camera.resize();

        if (this.renderer)
            this.renderer.resize();

        if (this.dom)
            this.dom.resize();

        if (this.responsiveHandler)
            this.responsiveHandler.resize();
    }

    checkURL(projectList) {
        let pathname = undefined;
        let hash = undefined;

        // NOTE-240705      pathname 확인
        /*
        if (window.location.pathname != '' && window.location.pathname != '/') {
            window.location.hash = '';
            const tempPathname = window.location.pathname.replace('/', '');
            if (tempPathname === 'about'
                || tempPathname == 'contact'
                || tempPathname == 'expertise') {
                pathname = tempPathname;
                this.jumpToMenu = true;
            }
            else {
                window.location.pathname = '';

            }
        }

        if (this.jumpToMenu) {
            this.event.trigger(this.event.URL_CHECK_FINISHED, [{
                pathname: pathname,
                hash: hash
            }]);
            return;
        }
        */
        if (window.location.hash.includes('#')) {
            const tempHash = window.location.hash.replace('#', '');
            if (tempHash === 'about') {
                this.jumpToMenu = true;
                hash = tempHash;
            }
            else if (tempHash === 'contact') {
                this.jumpToMenu = true;
                hash = tempHash;
            }
            else if (tempHash === 'expertise') {
                this.jumpToMenu = true;
                hash = tempHash;
            }

            if (this.jumpToMenu) {
                this.event.trigger(this.event.URL_CHECK_FINISHED, [{
                    pathname: pathname,
                    hash: hash
                }]);
            }
        }
        // //

        // NOTE-240705      hash 확인
        if (window.location.hash.includes('#')) {
            const tempHash = window.location.hash.replace('#', '');
            for (let i = 0; i < projectList.length; ++i) {
                const tempTitle = projectList[i].title.replace(/ /g, '_');
                if (tempTitle.includes(tempHash)) {
                    this.jumpToProject = true;
                    hash = tempHash;
                    this.event.trigger(this.event.URL_CHECK_FINISHED, [{
                        pathname: pathname,
                        hash: hash
                    }]);
                    return;
                }
            }
        }

        this.event.trigger(this.event.URL_CHECK_FINISHED, [{
            pathname: pathname,
            hash: hash
        }]);
        // //
    }

    findProjectUrl(hash) {
        const modifiedHash = hash.replace(/ /g, '_');
        for (let i = 0; i < this.projectDataList.length; ++i) {
            const tempTitle = this.projectDataList[i].title.replace(/ /g, '_');
            if (tempTitle.includes(modifiedHash)) {
                return {
                    title: tempTitle,
                    url: this.projectDataList[i].url
                };
            }
        }
        return undefined;
    }
}