import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core.js';
import Event from '../Event.js';
import PanelBase from './PanelBase.js';
import GeometryUtils from '../GeometryUtils.js';
import ExpertiseVideoCircle from './ExpertiseVideoCircle.js';

import { MeshLine, MeshLineMaterial } from 'three.meshline';


// NOTE-240221      역량을 담고 있음. 원 4개
export default class ExpertisePanel extends PanelBase {
    constructor(_options) {
        super(_options);

        this.structure = _options.structure;

        this.core = new Core();
        this.config = this.core.config;
        this.debug = this.core.debug;
        this.textureLoader = this.core.textureLoader;
        this.layer = this.core.layer;
        this.geometryUtils = new GeometryUtils();
        this.event = new Event();

        this.awake();
        this.draw();

        this.event.on(this.event.STRUCTURE_MOTION_FINISHED, () => {
            this.adjustMenuButtonPos();
        });

        this.event.on(this.event.UI_MENULINE_HOVERED, (name) => {
            if (name == 'expertise' && this.isExpertiseMenu === false) {
                this.changeOpacity(1);
            }
        });
        this.event.on(this.event.UI_MENULINE_HOVEROUTED, (name) => {
            if (name == 'expertise' && this.isExpertiseMenu === false) {
                this.changeOpacity(this.initOpacity);
            }
        });

        this.event.on(this.event.UI_MENUBUTTON_CLICKED, (key) => {
            if (key === 'expertise') {
                this.isExpertiseMenu = true;
                this.changeOpacity(0);
            }
        });

        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED, () => {
            if (this.isExpertiseMenu) {
                this.isExpertiseMenu = false;
                this.changeOpacity(this.initOpacity);
            }
        });
    }

    awake() {
        this.subCircleRadius = Math.sqrt(2) * this.mainCircleRadius - this.mainCircleRadius;
        this.initOpacity = 0.5;
        this.isExpertiseMenu = false;

        // NOTE-240315      메뉴 버튼의 위치를 세팅하기 위함
        this.buttonPosObj = new THREE.Mesh(new THREE.BoxGeometry(0.1, 0.1, 0.1), new THREE.MeshBasicMaterial());
        this.buttonPosObj.position.set(5.1, 5.1, 0);
        this.buttonPosObj.visible = false;
        this.group.add(this.buttonPosObj);
        // //
    }

    draw() {
        // NOTE-240117      Wire Circle 그리는 부분
        const subWireCircleGeom = this.geometryUtils.getCircleGeometry(
            this.subCircleRadius,
            this.mainCircleSegment
        );
        const subWireCircleLine = new MeshLine();
        subWireCircleLine.setGeometry(subWireCircleGeom);
        const subWireCircleMat = new MeshLineMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: this.isFadeState ? this.fadeStateLineOpacity : this.defaultLineOpacity,
            lineWidth: this.mainCircleWidth
        });

        this.subWireCircleLT = new THREE.Mesh(subWireCircleLine, subWireCircleMat);
        this.subWireCircleLT.position.set(-this.subCircleRadius, this.subCircleRadius, 0);
        this.subWireCircleLT.visible = false;

        this.subWireCircleRT = new THREE.Mesh(subWireCircleLine, subWireCircleMat);
        this.subWireCircleRT.position.set(this.subCircleRadius, this.subCircleRadius, 0);
        this.subWireCircleRT.visible = false;

        this.subWireCircleLB = new THREE.Mesh(subWireCircleLine, subWireCircleMat);
        this.subWireCircleLB.position.set(-this.subCircleRadius, -this.subCircleRadius, 0);
        this.subWireCircleLB.visible = false;

        this.subWireCircleRB = new THREE.Mesh(subWireCircleLine, subWireCircleMat);
        this.subWireCircleRB.position.set(this.subCircleRadius, -this.subCircleRadius, 0);
        this.subWireCircleRB.visible = false;

        this.group.add(this.subWireCircleLT, this.subWireCircleRT, this.subWireCircleLB, this.subWireCircleRB);
        ////

        // NOTE-240117      Content 그리는 부분
        const leftTopScript = new ExpertiseVideoCircle({
            structure: this.structure,
            key: 'web',
            filePath: './images/thumbnail_expertise_web.jpg',
            bigRadius: this.mainCircleRadius,
            radius: this.subCircleRadius,
            initPos: {
                x: -this.subCircleRadius,
                y: this.subCircleRadius,
                z: 0
            },
            initOpacity: this.initOpacity,
            onload: (obj) => {
                this.subCircleLT = obj;
                this.subCircleLT.visible = false;
                this.group.add(this.subCircleLT);
                this.subCircleLT.script = leftTopScript;
                this.summitRaycastCallback(this.subCircleLT);
            }
        });

        const rightTopScript = new ExpertiseVideoCircle({
            structure: this.structure,
            key: 'app',
            filePath: './images/thumbnail_expertise_app.jpg',
            bigRadius: this.mainCircleRadius,
            radius: this.subCircleRadius,
            initPos: {
                x: this.subCircleRadius,
                y: this.subCircleRadius,
                z: 0
            },
            initOpacity: this.initOpacity,
            onload: (obj) => {
                this.subCircleRT = obj;
                this.subCircleRT.visible = false;
                this.group.add(this.subCircleRT);
                this.subCircleRT.script = rightTopScript;
                this.summitRaycastCallback(this.subCircleRT);
            }
        });

        const leftBottomScript = new ExpertiseVideoCircle({
            structure: this.structure,
            key: 'newmedia',
            filePath: './images/thumbnail_expertise_newmedia.jpg',
            bigRadius: this.mainCircleRadius,
            radius: this.subCircleRadius,
            initPos: {
                x: -this.subCircleRadius,
                y: -this.subCircleRadius,
                z: 0
            },
            initOpacity: this.initOpacity,
            onload: (obj) => {
                this.subCircleLB = obj;
                this.subCircleLB.visible = false;
                this.group.add(this.subCircleLB);
                this.subCircleLB.script = leftBottomScript;
                this.summitRaycastCallback(this.subCircleLB);
            }
        });

        const rightBottomScript = new ExpertiseVideoCircle({
            structure: this.structure,
            key: 'motion',
            filePath: './images/thumbnail_expertise_motion.jpg',
            bigRadius: this.mainCircleRadius,
            radius: this.subCircleRadius,
            initPos: {
                x: this.subCircleRadius,
                y: -this.subCircleRadius,
                z: 0
            },
            initOpacity: this.initOpacity,
            onload: (obj) => {
                this.subCircleRB = obj;
                this.subCircleRB.visible = false;
                this.group.add(this.subCircleRB);
                this.subCircleRB.script = rightBottomScript;
                this.summitRaycastCallback(this.subCircleRB);
            }
        });
    }

    update() {
        this.event.trigger(this.event.UI_MENUBUTTON_MOVED, ['expertise',
            this.group.localToWorld(this.buttonPosObj.position.clone())
        ]);
    }

    showContent(timeValue) {
        if (this.subCircleLT == undefined
            || this.subCircleRT == undefined
            || this.subCircleLB == undefined
            || this.subCircleRB == undefined)
            return;

        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const animObj = { opacity: 0 };

        if (time <= 0) {
            this.subCircleRT.layers.enable(this.layer.raycastTarget);
            this.subCircleLB.layers.enable(this.layer.raycastTarget);
            this.subCircleRB.layers.enable(this.layer.raycastTarget);
            this.subCircleLT.layers.enable(this.layer.raycastTarget);

            this.subCircleRT.visible = true;
            this.subCircleLB.visible = true;
            this.subCircleRB.visible = true;
            this.subCircleLT.visible = true;

            this.subCircleLT.material.opacity = this.initOpacity;
            this.subCircleRT.material.opacity = this.initOpacity;
            this.subCircleLB.material.opacity = this.initOpacity;
            this.subCircleRB.material.opacity = this.initOpacity;
        }
        else {
            this.contentAnim = gsap.to(animObj,
                {
                    opacity: this.initOpacity,
                    duration: time,
                    ease: this.defaultEase,
                    onStart: () => {
                        this.subCircleRT.layers.enable(this.layer.raycastTarget);
                        this.subCircleLB.layers.enable(this.layer.raycastTarget);
                        this.subCircleRB.layers.enable(this.layer.raycastTarget);
                        this.subCircleLT.layers.enable(this.layer.raycastTarget);

                        this.subCircleRT.visible = true;
                        this.subCircleLB.visible = true;
                        this.subCircleRB.visible = true;
                        this.subCircleLT.visible = true;
                    },
                    onUpdate: () => {
                        this.subCircleLT.material.opacity = animObj.opacity;
                        this.subCircleRT.material.opacity = animObj.opacity;
                        this.subCircleLB.material.opacity = animObj.opacity;
                        this.subCircleRB.material.opacity = animObj.opacity;
                    }
                }
            );
        }
    }

    hideContent(timeValue) {
        const time = timeValue === undefined ? this.defaultFadeTime : timeValue;
        if (this.contentAnim)
            this.contentAnim.pause();

        const animObj = { opacity: 0 };

        if (time <= 0) {
            this.subCircleRT.layers.disable(this.layer.raycastTarget);
            this.subCircleLB.layers.disable(this.layer.raycastTarget);
            this.subCircleRB.layers.disable(this.layer.raycastTarget);
            this.subCircleLT.layers.disable(this.layer.raycastTarget);

            this.subCircleLT.material.opacity = 0;
            this.subCircleRT.material.opacity = 0;
            this.subCircleLB.material.opacity = 0;
            this.subCircleRB.material.opacity = 0;

            this.subCircleLT.visible = false;
            this.subCircleRT.visible = false;
            this.subCircleLB.visible = false;
            this.subCircleRB.visible = false;
        }
        else {
            this.contentAnim = gsap.to(animObj,
                {
                    opacity: 0,
                    duration: time,
                    ease: this.defaultEase,
                    onStart: () => {
                        this.subCircleRT.layers.disable(this.layer.raycastTarget);
                        this.subCircleLB.layers.disable(this.layer.raycastTarget);
                        this.subCircleRB.layers.disable(this.layer.raycastTarget);
                        this.subCircleLT.layers.disable(this.layer.raycastTarget);
                    },
                    onUpdate: () => {
                        this.subCircleLT.material.opacity = animObj.opacity;
                        this.subCircleRT.material.opacity = animObj.opacity;
                        this.subCircleLB.material.opacity = animObj.opacity;
                        this.subCircleRB.material.opacity = animObj.opacity;
                    },
                    onComplete: () => {
                        this.subCircleLT.visible = false;
                        this.subCircleRT.visible = false;
                        this.subCircleLB.visible = false;
                        this.subCircleRB.visible = false;
                    }
                }
            );
        }
    }

    adjustMenuButtonPos() {
        const newButtonPos = this.group.worldToLocal(this.buttonPosObj.position.clone());
        this.buttonPosObj.position.set(newButtonPos.x, newButtonPos.y, newButtonPos.z);
    }

    summitRaycastCallback(target) {
        target.onhover = function () {
            if (this.isExpertiseMenu === false) {
                this.changeTargetOpacity(target, 1);
            }
        }.bind(this);

        target.onhoverOut = function () {
            if (this.isExpertiseMenu === false)
                this.changeTargetOpacity(target, this.initOpacity);
        }.bind(this);

        target.onclick = function () {
            this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, ['expertise']);
        }.bind(this);
    }

    changeTargetOpacity(target, targetOpacity) {
        // if (this.hoverAnim)
        //     this.hoverAnim.pause();

        this.hoverAnim = gsap.to(target.material, {
            opacity: targetOpacity,
            duration: 0.2
        });
    }

    changeOpacity(targetOpacity) {
        // if (this.hoverAnim)
        //     this.hoverAnim.pause();

        this.hoverAnim = gsap.to(this.subCircleLT.material, {
            opacity: targetOpacity,
            duration: 0.2,
            onUpdate: () => {
                this.subCircleLB.material.opacity = this.subCircleLT.material.opacity;
                this.subCircleRT.material.opacity = this.subCircleLT.material.opacity;
                this.subCircleRB.material.opacity = this.subCircleLT.material.opacity;
            }
        });
    }
}