import * as THREE from 'three';
import Core from './Core';
import Event from './Event';


export default class Raycaster {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.config = this.core.config;
        this.camera = this.core.camera;
        this.layer = this.core.layer;
        this.scene = this.core.scene;

        this.awake();

        // NOTE-240702      기본 정의
        this.event.on(this.event.REQUEST_RAYCAST_ENABLE, () => {
            this.enabled = true;
        });
        this.event.on(this.event.REQUEST_RAYCAST_DISABLE, () => {
            this.enabled = false;
            if (this.hitInfo != undefined) {
                this.hitInfo.object.onhoverOut();
                this.hitInfo = undefined;
            }
        });
        // //

        // NOTE-240703      실행 초반 부분
        this.event.on(this.event.KV_CLOSED, () => {
            this.isIntroFinished = true;
        });
        // //

        // NOTE-240703      메뉴 버튼 관련
        this.event.on(this.event.UI_MENUBUTTON_CLICKED, () => {
            this.event.trigger(this.event.REQUEST_RAYCAST_DISABLE);
        });
        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED_END, () => {
            this.event.trigger(this.event.REQUEST_RAYCAST_ENABLE);
        });
        // //

        // NOTE-240703      리스트뷰 관련
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED, () => {
            this.event.trigger(this.event.REQUEST_RAYCAST_DISABLE);
        });
        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED_END, () => {
            this.event.trigger(this.event.REQUEST_RAYCAST_ENABLE);
        });
        // //

        // NOTE-240703      호버시
        this.event.on(this.event.UI_MENULINE_HOVERED, () => {
            this.isMarkdownHovered = true;
        });
        this.event.on(this.event.UI_MENULINE_HOVEROUTED, () => {
            this.isMarkdownHovered = false;
        });
        this.event.on(this.event.UI_SWITCHBUTTON_HOVERED, () => {
            this.isMarkdownHovered = true;
        });
        this.event.on(this.event.UI_SWITCHBUTTON_HOVEROUTED, () => {
            this.isMarkdownHovered = false;
        });
        this.event.on(this.event.LOGOTEXT_HOVERED, () => {
            this.isMarkdownHovered = true;
        });
        this.event.on(this.event.LOGOTEXT_HOVEROUTED, () => {
            this.isMarkdownHovered = false;
        });
        // //

        if (this.config.isMobileDevice) {
            window.addEventListener('touchstart', (event) => this.onTouchDown(event));
            window.addEventListener('touchend', (event) => this.onTouchUp(event));
        }
        else {
            window.addEventListener('mousemove', (event) => this.onMouseMove(event));
            window.addEventListener('mousedown', (event) => this.onMouseDown(event));
            window.addEventListener('mouseup', (event) => this.onMouseUp(event));
        }
    }

    awake() {
        this.instance = new THREE.Raycaster();
        this.instance.far = 100;
        this.instance.camera = this.camera;
        this.instance.layers.set(this.layer.raycastTarget);

        this.enabled = true;
        this.isIntroFinished = false;
        this.isMarkdownHovered = false;
        this.hitInfo = undefined;
        this.onMouseDownPos = undefined;
        this.mouse = new THREE.Vector2();
    }

    update() {
        if (this.hitInfo != undefined || this.isMarkdownHovered)
            this.event.trigger(this.event.TRIGGER_AUTOROTATE_STOP);
    }

    onMouseMove(event) {
        // NOTE-231228      좌표를 -1 ~ 1 로 변환해줌
        this.mouse.x = (event.clientX / this.config.width) * 2 - 1;
        this.mouse.y = -(event.clientY / this.config.height) * 2 + 1;
        // //

        if (this.enabled == false
            || this.isIntroFinished == false
            || this.isMarkdownHovered) {
            if (this.hitInfo) {
                this.hitInfo.object.onhoverOut();
                this.hitInfo = undefined;
            }
            return;
        }

        this.updateRaycast(false);
    }

    onMouseDown(event) {
        if (this.hitInfo != undefined) {
            this.onMouseDownPos = {
                x: event.clientX,
                y: event.clientY
            };
        }
        else {
            this.onMouseDownPos = undefined;
        }
    }

    onMouseUp(event) {
        if (this.hitInfo != undefined
            && this.onMouseDownPos != undefined
            && this.onMouseDownPos.x === event.clientX
            && this.onMouseDownPos.y === event.clientY) {
            this.onClick();
        }

        this.onMouseDownPos = undefined;
    }

    onTouchDown(event) {
        if (this.enabled == false || this.isIntroFinished == false) {
            this.hitInfo = undefined;
            return;
        }

        let touch = undefined;
        if (event.touches != undefined && event.touches.length > 0)
            touch = event.touches[0];
        else if (event.changedTouches != undefined && event.changedTouches.length > 0)
            touch = event.changedTouches[0];

        this.onMouseDownPos = {
            x: touch.clientX,
            y: touch.clientY
        };
    }

    onTouchUp(event) {
        if (this.enabled == false || this.isIntroFinished == false) {
            this.hitInfo = undefined;
            return;
        }

        let touch = undefined;
        if (event.touches != undefined && event.touches.length > 0)
            touch = event.touches[0];
        else if (event.changedTouches != undefined && event.changedTouches.length > 0)
            touch = event.changedTouches[0];

        this.mouse.x = (touch.clientX / this.config.width) * 2 - 1;
        this.mouse.y = -(touch.clientY / this.config.height) * 2 + 1;

        if (touch.clientX != this.onMouseDownPos.x || touch.clientY != this.onMouseDownPos.y) {
            this.hitInfo = undefined;
        }
        else {
            this.updateRaycast(true);
            this.onClick();
        }

        this.onMouseDownPos = undefined;
    }

    onClick() {
        if (this.hitInfo != undefined) {
            this.hitInfo.object.onclick();
        }
    }

    updateRaycast(withoutHover) {
        this.instance.setFromCamera(this.mouse, this.camera.instance);
        const intersects = this.instance.intersectObjects(this.scene.children);
        if (intersects.length == 0) {
            // NOTE-240228      맞은건 없는데, 이전것이 있을때
            if (this.hitInfo != undefined && withoutHover == false) {
                this.hitInfo.object.onhoverOut();
            }
            this.hitInfo = undefined;
            return;
        }

        // NOTE-240228      쓸데없는거 쏘고있으면 null
        if (intersects[0].object instanceof THREE.Mesh == false) {
            if (this.hitInfo != undefined && withoutHover == false) {
                this.hitInfo.object.onhoverOut();
            }
            this.hitInfo = undefined;
            return;
        }

        if (this.hitInfo != undefined) {
            // NOTE-240228      같은거 쏘고있으면 할 일이 없어서 return
            if (this.hitInfo.object === intersects[0].object)
                return;

            // NOTE-240228      뭔가를 쏘고 있는데, 이전꺼랑 다르면 이전꺼 hoverOut
            if (this.hitInfo.object && withoutHover == false) {
                this.hitInfo.object.onhoverOut();
            }
        }

        this.hitInfo = {
            object: intersects[0].object,
            pos: { x: this.mouse.x, y: this.mouse.y }
        };

        if (withoutHover == false)
            this.hitInfo.object.onhover();
    }
}