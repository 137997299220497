import * as THREE from 'three';

import Event from '../Event';
import Core from '../Core';


export default class MenuTextHandler {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.event.on(this.event.UI_MENUTEXT_HOVERED, (key) => {
            this.onHoverWithKey(key);
        });
        this.event.on(this.event.UI_MENUTEXT_HOVEROUTED, (key) => {
            this.onHoverOutWithKey(key);
        });

        this.awake();
    }

    awake() {
        this.texts = document.getElementsByClassName('menuUI-text');

        for (let i = 0; i < this.texts.length; ++i) {
            const key = this.texts[i].getAttribute('key');

            this.texts[i].onclick = () => {
                this.event.trigger(this.event.UI_MENUBUTTON_CLICKED_WRAPPER, [key]);
            };
            this.texts[i].onmouseover = () => {
                this.onHover(this.texts[i]);
                this.event.trigger(this.event.UI_MENULINE_HOVERED, [key]);
                this.event.trigger(this.event.UI_MENUBUTTON_HOVERED, [key]);
            };
            this.texts[i].onmouseout = () => {
                this.onHoverOut(this.texts[i]);
                this.event.trigger(this.event.UI_MENULINE_HOVEROUTED, [key]);
                this.event.trigger(this.event.UI_MENUBUTTON_HOVEROUTED, [key]);
            };
        }
    }

    onHover(obj) {
        obj.classList.add('hover');
    }

    onHoverWithKey(key) {
        for (let i = 0; i < this.texts.length; ++i) {
            const thisKey = this.texts[i].getAttribute('key');
            if (thisKey === key) {
                this.onHover(this.texts[i]);
                break;
            }
        }
    }

    onHoverOut(obj) {
        obj.classList.remove('hover');
    }

    onHoverOutWithKey(key) {
        for (let i = 0; i < this.texts.length; ++i) {
            const thisKey = this.texts[i].getAttribute('key');
            if (thisKey === key) {
                this.onHoverOut(this.texts[i]);
                break;
            }
        }
    }
} 