import * as THREE from 'three';


import Core from '../Core';
import Event from "../Event";


export default class IframeProjectDetail {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();

        this.awake();

        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_ENABLE, (src) => {
            this.enableIframe(src);
        });

        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            this.disableIframe();
        });

        this.event.on(this.event.PROJECTITEM_HOVERED, (target) => {
            this.showProjectTitle(target.title);
        });
        this.event.on(this.event.PROJECTITEM_HOVEROUTED, () => {
            this.hideProjectTitle();
        });
    }

    awake() {
        this.iframe = document.getElementById('project-detail-iframe');
        this.iframeContainer = document.getElementById('project-detail-container');
        this.projectTitle = document.getElementById('project-title-text');
    }

    enableIframe(src) {
        this.iframeContainer.classList.add('show');
        this.iframe.classList.add('show');
        this.iframe.src = src;
    }

    disableIframe() {
        this.iframeContainer.classList.remove('show');
        this.iframe.classList.remove('show');
        setTimeout(() => this.iframe.src = '', 500);
    }

    showProjectTitle(titleName) {
        this.projectTitle.classList.add('show');
        this.projectTitle.innerHTML = titleName;
    }

    hideProjectTitle() {
        this.projectTitle.classList.remove('show');
        this.projectTitle.innerHTML = '';
    }
}