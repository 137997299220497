import * as THREE from 'three';
import gsap from 'gsap';

import Core from '../Core';
import Event from '../Event.js';
import EmptyPanel from "./EmptyPanel.js";
import ContactPanel from "./ContactPanel.js";
import AboutPanel from "./AboutPanel.js";
import ExpertisePanel from "./ExpertisePanel.js";
import ProjectItemPanel from "./ProjectItemPanel.js";
import SphereParticle from './SphereParticle';

import GeometryUtils from '../GeometryUtils.js';
import CustomOrbitControls from '../CustomOrbitControls.js';


export default class Structure {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.scene = this.core.scene;
        this.raycaster = this.core.raycaster;
        this.camera = this.core.camera;
        this.geometryUtils = new GeometryUtils();
        this.intro = this.core.intro;
        this.debug = this.core.debug;

        this.awake();
        this.draw();
        this.setDebug();

        // NOTE-240702      실행 초반 부분
        this.event.on(this.event.INTRO_FINISHED, (isSkipped) => {
            if (isSkipped)
                this.quickPlayIntro();
            else
                this.playIntro();
        });
        this.event.on(this.event.KV_CLOSED, () => {
            this.customOrbitControls.forIntro = false;

            if (this.core.jumpToProject === false && this.core.jumpToMenu === false) {
                this.customOrbitControls.enabled = true;
                this.customOrbitControls.enableAutoRotate = true;
                this.setActiveDivideAndFollow(true);
            }
        });
        // //

        // NOTE-240702      메뉴 버튼 관련
        this.event.on(this.event.UI_MENUBUTTON_CLICKED, (key, isQuick) => {
            this.setActiveDivideAndFollow(false);

            if (isQuick)
                this.onMenuButtonClicked(key, 0);
            else
                this.onMenuButtonClicked(key);
        });

        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED, (key) => {
            this.onMenuBackButtonClicked(key);
        });

        this.event.on(this.event.UI_MENUBACKBUTTON_CLICKED_END, () => {
            this.setActiveDivideAndFollow(true);
        });
        // //

        // NOTE-240703      리스트뷰 관련
        this.event.on(this.event.UI_SWITCHBUTTON_CLICKED, () => {
            this.setActiveDivideAndFollow(false);
            this.onSwitchButtonClicked();
        });

        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED, () => {
            this.onSwitchBackButtonClicked();
        });

        this.event.on(this.event.UI_SWITCHBACKBUTTON_CLICKED_END, () => {
            this.setActiveDivideAndFollow(true);
        });
        // //

        // NOTE-240705      프로젝트 상세 관련
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_ENABLE, () => {
            this.setActiveDivideAndFollow(false);
            this.customOrbitControls.enabled = false;
        });
        this.event.on(this.event.REQUEST_PROJECTDETAIL_IFRAME_DISABLE, () => {
            this.setActiveDivideAndFollow(true);
            this.customOrbitControls.enabled = true;
        });
        // //
    }

    update() {
        if (this.sphereProjectPreview)
            this.sphereProjectPreview.update();

        if (this.emptyPanel)
            this.emptyPanel.update();

        if (this.contactPanel)
            this.contactPanel.update();

        if (this.aboutPanel)
            this.aboutPanel.update();

        if (this.expertisePanel)
            this.expertisePanel.update();

        if (this.customOrbitControls)
            this.customOrbitControls.update();

        if (this.isDivided) {
            const targetQuat = new THREE.Quaternion().copy(this.group.quaternion);
            this.structureGroup.quaternion.slerp(targetQuat, this.lerpSpeed);
        }
    }

    awake() {
        this.group = new THREE.Group();
        this.scene.add(this.group);

        this.isDivided = false;
        this.lerpSpeed = 0.175;
        this.structureGroup = new THREE.Group();
        this.group.add(this.structureGroup);

        this.customOrbitControls = new CustomOrbitControls({
            targetObject: this.group
        });
        this.customOrbitControls.enabled = false;
        this.customOrbitControls.enableAutoRotate = false;

        this.mouseBlock = false;

        this.initScale = 8;
        this.defaultScale = 1.47;
        this.group.scale.setScalar(this.initScale);
    }

    draw() {
        this.emptyPanel = new EmptyPanel({ structure: this });
        this.contactPanel = new ContactPanel({ structure: this });
        this.aboutPanel = new AboutPanel({ structure: this });
        this.expertisePanel = new ExpertisePanel({ structure: this });
        this.sphereParticle = new SphereParticle({ structure: this });
        this.projectItemPanel = new ProjectItemPanel({ structure: this });
    }

    playIntro() {
        if (this.introGroupAnim)
            this.introGroupAnim.pause();
        if (this.introAnim)
            this.introAnim.pause();

        this.introGroupAnim = gsap.to(this.group.scale, {
            x: this.defaultScale,
            delay: 0.5,
            duration: 1,
            ease: 'power1.inOut',
            onUpdate: () => {
                this.group.scale.y = this.group.scale.x;
                this.group.scale.z = this.group.scale.x;
            }
        });

        const animObj = {
            xRot: 0,
            yRot: 0,
            zRot: 0,
            zAxisRot: 0,
            diagonalRot: 0
        };
        let isTriggered = false;
        this.introAnim = gsap.fromTo(animObj, {
            xRot: 0,
            yRot: THREE.MathUtils.degToRad(360),
            zRot: 0,
            zAxisRot: 0,
            diagonalRot: 0
        }, {
            xRot: THREE.MathUtils.degToRad(10),
            yRot: THREE.MathUtils.degToRad(57.5), // 57.5
            zRot: THREE.MathUtils.degToRad(0),
            zAxisRot: THREE.MathUtils.degToRad(90),
            diagonalRot: THREE.MathUtils.degToRad(45),
            duration: 2.25,
            ease: 'power1.out',
            onStart: () => {
                this.emptyPanel.showOutline();
                this.contactPanel.showOutline();
                this.aboutPanel.showOutline();
                this.expertisePanel.showOutline();

                this.camera.switchCameraMode(false, 0.5);
                this.contactPanel.showContent();
                this.aboutPanel.showContent();
                this.expertisePanel.showContent();
                this.sphereParticle.showContent();
            },
            onUpdate: () => {
                this.group.rotation.x = animObj.xRot;
                this.group.rotation.y = animObj.yRot;
                this.group.rotation.z = animObj.zRot;
                this.aboutPanel.group.rotation.x = animObj.zAxisRot;
                this.expertisePanel.group.rotation.x = animObj.diagonalRot;

                if (animObj.yRot < THREE.MathUtils.degToRad(180) && isTriggered == false) {
                    isTriggered = true;
                    this.projectItemPanel.playItemRing();
                }
            },
            onComplete: () => {
                this.event.trigger(this.event.STRUCTURE_MOTION_FINISHED);
                this.camera.orbitControls.enabled = true;
            }
        });
    }

    quickPlayIntro() {
        if (this.introGroupAnim)
            this.introGroupAnim.pause();
        if (this.introAnim)
            this.introAnim.pause();

        this.group.scale.setScalar(this.defaultScale);
        this.emptyPanel.showOutline();
        this.contactPanel.showOutline();
        this.aboutPanel.showOutline();
        this.expertisePanel.showOutline();

        this.camera.quickSwitchCameraMode(false);

        // onStart
        this.contactPanel.showContent();
        this.aboutPanel.showContent();
        this.expertisePanel.showContent();
        this.sphereParticle.showContent();

        // onUpdate
        this.group.rotation.x = THREE.MathUtils.degToRad(10);
        this.group.rotation.y = THREE.MathUtils.degToRad(57.5);
        this.group.rotation.z = THREE.MathUtils.degToRad(0);
        this.aboutPanel.group.rotation.x = THREE.MathUtils.degToRad(90);
        this.expertisePanel.group.rotation.x = THREE.MathUtils.degToRad(45);

        // onComplete
        this.event.trigger(this.event.STRUCTURE_MOTION_FINISHED, [true]);
        this.camera.orbitControls.enabled = true;

        setTimeout(() => {
            this.projectItemPanel.playItemRing(0);
        }, this.core.deltaTime * 1000);
    }

    setActiveDivideAndFollow(value) {
        this.isDivided = value;
        this.structureGroup.scale.set(
            value ? this.group.scale.x : 1,
            value ? this.group.scale.y : 1,
            value ? this.group.scale.z : 1
        );
        this.structureGroup.position.set(
            value ? this.group.position.x : 0,
            value ? this.group.position.y : 0,
            value ? this.group.position.z : 0
        );
        this.structureGroup.rotation.set(
            value ? this.group.rotation.x : 0,
            value ? this.group.rotation.y : 0,
            value ? this.group.rotation.z : 0
        );

        if (value) {
            this.group.remove(this.structureGroup);
            this.scene.add(this.structureGroup);
        }
        else {
            this.scene.remove(this.structureGroup);
            this.group.add(this.structureGroup);
        }
    }

    onMenuButtonClicked(key, time) {
        if (key === 'contact')
            this.onContactMenuClicked(key, time);
        else if (key === 'expertise')
            this.onExpertiseMenuClicked(key, time);
        else if (key === 'about')
            this.onAboutMenuClicked(key, time);
    }

    onMenuBackButtonClicked(key) {
        if (key === 'contact')
            this.onBackButtonClickedFromContact(key);
        else if (key === 'expertise')
            this.onBackButtonClickedFromExpertise(key);
        else if (key === 'about')
            this.onBackButtonClickedFromAbout(key);
    }

    playMotionsForMenu(angleX, angleY, additionalFunc) {
        if (this.rotationAnim)
            this.rotationAnim.pause();
        if (this.scaleAnim)
            this.scaleAnim.pause();

        const clickMotionTime = 0.65;
        const clickMotionScaleMtp = {
            pc: 1.78,
            mobile: 1.36
        };
        this.camera.resetToPerspective(clickMotionTime);

        const control = this.customOrbitControls;
        this.rotationAnim = gsap.to(control.targetObject.rotation, {
            x: THREE.MathUtils.degToRad(angleX),
            y: THREE.MathUtils.degToRad(angleY),
            duration: clickMotionTime,
            onStart: () => {
                control.enabled = false;
                this.camera.orbitControls.enabled = false;
            },
            onComplete: () => {
                control.targetRotation.x = control.targetObject.rotation.x;
                control.targetRotation.y = control.targetObject.rotation.y;
            },
        });

        const animObj = { scale: 1 };
        const initScale = this.defaultScale;
        this.scaleAnim = gsap.to(animObj, {
            scale: this.core.config.isMobile ? clickMotionScaleMtp.mobile : clickMotionScaleMtp.pc,
            duration: clickMotionTime,
            onUpdate: () => {
                this.group.scale.setScalar(initScale * animObj.scale);
                this.event.trigger(this.event.REQUEST_DISABLE_CLOSE_LINES);
            }
        });

        additionalFunc();
    }

    quickPlayMotionsForMenu(angleX, angleY, additionalFunc) {
        if (this.rotationAnim)
            this.rotationAnim.pause();
        if (this.scaleAnim)
            this.scaleAnim.pause();

        const clickMotionScaleMtp = {
            pc: 1.78,
            mobile: 1.36
        };
        this.camera.resetToPerspective(0);

        const control = this.customOrbitControls;
        control.enabled = false;
        this.camera.orbitControls.enabled = false;
        control.targetObject.rotation.x = THREE.MathUtils.degToRad(angleX);
        control.targetObject.rotation.y = THREE.MathUtils.degToRad(angleY);
        control.targetRotation.x = control.targetObject.rotation.x;
        control.targetRotation.y = control.targetObject.rotation.y;

        const initScale = this.defaultScale;
        const targetScale = this.core.config.isMobile ? clickMotionScaleMtp.mobile : clickMotionScaleMtp.pc;
        this.group.scale.setScalar(initScale * targetScale);

        additionalFunc();

        setTimeout(() => {
            this.event.trigger(this.event.REQUEST_DISABLE_CLOSE_LINES);
        }, this.core.deltaTime * 1000 * 2);
    }

    playBackMotionsForMenu(additionalFunc, key) {
        const clickMotionTime = 0.65;

        if (this.rotationAnim)
            this.rotationAnim.pause();
        if (this.scaleAnim)
            this.scaleAnim.pause();

        const control = this.customOrbitControls;
        this.rotationAnim = gsap.to(control.targetObject.rotation, {
            x: control.initTargetRotation.x,
            y: control.initTargetRotation.y,
            duration: clickMotionTime,
            onComplete: () => {
                control.targetRotation.x = control.targetObject.rotation.x;
                control.targetRotation.y = control.targetObject.rotation.y;

                control.enabled = true;
                this.camera.orbitControls.enabled = true;
                this.event.trigger(this.event.UI_MENUBACKBUTTON_CLICKED_END, [key]);
                this.event.trigger(this.event.REQUEST_ENABLE_ALL_LINES);
            },
        });

        const animObj = { scale: this.group.scale.x };
        this.scaleAnim = gsap.to(animObj, {
            scale: this.defaultScale,
            duration: clickMotionTime,
            onUpdate: () => {
                this.group.scale.setScalar(animObj.scale);
            }
        });

        additionalFunc();
    }

    onExpertiseMenuClicked(key, time) {
        const additionalFunc = function () {
            this.emptyPanel.hideOutline(time);

            this.contactPanel.hideOutline(time);
            this.contactPanel.hideContent(time);

            this.aboutPanel.hideOutline(time);
            this.aboutPanel.hideContent(time);

            this.sphereParticle.hideContent(time);

            this.projectItemPanel.hideContent(time);
            this.projectItemPanel.showOutline(time);

            this.expertisePanel.isFadeState = true;
            this.expertisePanel.showOutline(time);
            this.expertisePanel.hideContent(time);
        }.bind(this);

        if (time == undefined || time > 0)
            this.playMotionsForMenu(-45, 0, additionalFunc, key);
        else
            this.quickPlayMotionsForMenu(-45, 0, additionalFunc, key);
    }

    onBackButtonClickedFromExpertise(key) {
        const additionalFunc = function () {
            this.emptyPanel.showOutline();

            this.contactPanel.showOutline();
            this.contactPanel.showContent();

            this.aboutPanel.showOutline();
            this.aboutPanel.showContent();

            this.sphereParticle.showContent();

            this.projectItemPanel.showContent();
            this.projectItemPanel.hideOutline();

            this.expertisePanel.isFadeState = false;
            this.expertisePanel.showOutline();
            this.expertisePanel.showContent();
        }.bind(this);

        this.playBackMotionsForMenu(additionalFunc, key);
    }

    onContactMenuClicked(key, time) {
        const additionalFunc = function () {
            this.emptyPanel.hideOutline(time);

            this.expertisePanel.hideOutline(time);
            this.expertisePanel.hideContent(time);

            this.aboutPanel.hideOutline(time);
            this.aboutPanel.hideContent(time);

            this.sphereParticle.hideContent(time);

            this.projectItemPanel.hideContent(time);
            this.projectItemPanel.showOutline(time);

            this.contactPanel.isFadeState = true;
            this.contactPanel.showOutline(time);
            this.contactPanel.hideContent(time);
        }.bind(this);

        if (time == undefined || time > 0)
            this.playMotionsForMenu(0, 0, additionalFunc, key);
        else
            this.quickPlayMotionsForMenu(0, 0, additionalFunc, key);
    }

    onBackButtonClickedFromContact(key) {
        const additionalFunc = function () {
            this.emptyPanel.showOutline();
            this.expertisePanel.showOutline();
            this.aboutPanel.showOutline();

            this.expertisePanel.showContent();
            this.aboutPanel.showContent();
            this.sphereParticle.showContent();

            this.projectItemPanel.showContent();
            this.projectItemPanel.hideOutline();

            this.contactPanel.isFadeState = false;
            this.contactPanel.showOutline();
            this.contactPanel.showContent();
        }.bind(this);

        this.playBackMotionsForMenu(additionalFunc, key);
    }

    onAboutMenuClicked(key, time) {
        const additionalFunc = function () {
            this.emptyPanel.hideOutline(time);

            this.expertisePanel.hideOutline(time);
            this.expertisePanel.hideContent(time);

            this.contactPanel.hideOutline(time);
            this.contactPanel.hideContent(time);

            this.sphereParticle.hideContent(time);

            this.projectItemPanel.hideContent(time);
            this.projectItemPanel.showOutline(time);

            this.aboutPanel.isFadeState = true;
            this.aboutPanel.showOutline(time);
            this.aboutPanel.hideContent(time);
        }.bind(this);

        if (time == undefined || time > 0)
            this.playMotionsForMenu(90, 0, additionalFunc, key);
        else
            this.quickPlayMotionsForMenu(90, 0, additionalFunc, key);
    }

    onBackButtonClickedFromAbout(key) {
        const additionalFunc = function () {
            this.emptyPanel.showOutline();
            this.expertisePanel.showOutline();
            this.contactPanel.showOutline();

            this.expertisePanel.showContent();
            this.contactPanel.showContent();
            this.sphereParticle.showContent();

            this.projectItemPanel.showContent();
            this.projectItemPanel.hideOutline();

            this.aboutPanel.isFadeState = false;
            this.aboutPanel.showOutline();
            this.aboutPanel.showContent();
        }.bind(this);

        this.playBackMotionsForMenu(additionalFunc, key);
    }

    onSwitchButtonClicked() {
        this.playMotionsFor2D(0, 90);
    }

    playMotionsFor2D(angleX, angleY) {
        const clickMotionTime = 0.5;
        const clickMotionScaleMtp = 1.75;

        this.camera.resetToPerspective(clickMotionTime);

        this.expertisePanel.hideContent();
        this.contactPanel.hideContent();
        this.aboutPanel.hideContent();
        this.sphereParticle.hideContent();
        this.projectItemPanel.alignFor2D();

        if (this.rotationAnim)
            this.rotationAnim.pause();
        if (this.scaleAnim)
            this.scaleAnim.pause();
        if (this.domAnim)
            this.domAnim.pause();

        const control = this.customOrbitControls;
        this.rotationAnim = gsap.to(control.targetObject.rotation, {
            x: THREE.MathUtils.degToRad(angleX),
            y: THREE.MathUtils.degToRad(angleY),
            duration: clickMotionTime,
            onStart: () => {
                control.enabled = false;
                this.camera.orbitControls.enabled = false;
            },
            onComplete: () => {
                control.targetRotation.x = control.targetObject.rotation.x;
                control.targetRotation.y = control.targetObject.rotation.y;
                this.event.trigger(this.event.UI_SWITCHBUTTON_CLICKED_END);
            },
        });

        const animObj = { scale: 1 };
        const initScale = this.defaultScale;
        this.scaleAnim = gsap.to(animObj, {
            scale: clickMotionScaleMtp,
            duration: clickMotionTime,
            onUpdate: () => {
                this.group.scale.setScalar(initScale * animObj.scale);
            }
        });

        this.domAnim = gsap.to({ dummy: 0 }, {
            dummy: 0,
            delay: 0.1,
            duration: 0.3,
            onStart: () => {
                this.emptyPanel.isFadeState = true;
                this.emptyPanel.showOutline();

                this.expertisePanel.hideOutline();
                this.contactPanel.hideOutline();
                this.aboutPanel.hideOutline();
            }
        });
    }

    onSwitchBackButtonClicked() {
        this.playReverseMotionsFor2D();
    }

    playReverseMotionsFor2D() {
        const clickMotionTime = 0.65;

        this.projectItemPanel.playItemRing(0.7);

        if (this.rotationAnim)
            this.rotationAnim.pause();
        if (this.scaleAnim)
            this.scaleAnim.pause();
        if (this.domAnim)
            this.domAnim.pause();

        const control = this.customOrbitControls;
        this.rotationAnim = gsap.to(control.targetObject.rotation, {
            x: control.initTargetRotation.x,
            y: control.initTargetRotation.y,
            duration: clickMotionTime,
            onComplete: () => {
                control.targetRotation.x = control.targetObject.rotation.x;
                control.targetRotation.y = control.targetObject.rotation.y;
                control.enabled = true;
                this.camera.orbitControls.enabled = true;
                this.event.trigger(this.event.UI_SWITCHBACKBUTTON_CLICKED_END);
            },
        });

        const animObj = { scale: this.group.scale.x };
        this.scaleAnim = gsap.to(animObj, {
            scale: this.defaultScale,
            duration: clickMotionTime,
            onUpdate: () => {
                this.group.scale.setScalar(animObj.scale);
            }
        });

        // this.domAnim = gsap.to({ dummy: 0 }, {
        //     dummy: 0,
        //     duration: 0.3,
        //     onComplete: () => {
        //         this.menuUI.classList.remove('hide');
        //     }
        // });

        this.emptyPanel.isFadeState = false;
        this.emptyPanel.showOutline();

        this.expertisePanel.showOutline();
        this.contactPanel.showOutline();
        this.aboutPanel.showOutline();
        this.projectItemPanel.hideOutline();

        this.expertisePanel.showContent();
        this.contactPanel.showContent();
        this.aboutPanel.showContent();
        this.sphereParticle.showContent();
        this.projectItemPanel.showContent();
    }





    setDebug() {
        const structureDebug = this.debug.addFolder('Structure');
        structureDebug
            .add(this, 'lerpSpeed')
            .min(0.01)
            .max(0.5)
            .step(0.001)
            .listen();
    }
}