export default class URLContainer {
    constructor(_options) {
        let rootUrl = window.location.host;
        if (rootUrl.includes('localhost'))
            rootUrl = 'dev.ninefive.org'; //'www.ninefive.com'; 

        this.contactUrl = `https://${rootUrl}/homepage/view/legacy/page/contact.html`;
        this.expertiseUrl = `https://${rootUrl}/homepage/view/page/expertise.html`;
        this.aboutUrl = `https://${rootUrl}/homepage/view/legacy/page/about.html`;
        this.projectListUrl = `https://${rootUrl}/view/page/projectList.html`;
    }
}