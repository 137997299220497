import * as THREE from 'three';
import gsap from 'gsap';

import Event from '../Event.js';
import Core from '../Core.js';


export default class TypingIntro {
    constructor(_options) {
        this.core = new Core();
        this.event = new Event();
        this.config = this.core.config;

        this.awake();

        this.event.on(this.event.INTRO_STARTED, () => {
            if (this.config.isMobile === false && this.isMac === false)
                this.isIntroStarted = true;
        });

        this.event.on(this.event.INIT_LOAD_FINISHED, () => {
            this.isLoadFinished = true;
            this.onIntroFinishCallback();

            if (this.core.jumpToProject === false && this.core.jumpToMenu === false) {
                if (this.config.isMobile || this.isMac)
                    this.isIntroStarted = true;
            }
        });


        this.skipHandler = function (event) {
            if (this.isIntroFinished === false)
                this.skip();
        }.bind(this);

        this.event.on(this.event.URL_CHECK_FINISHED, () => {
            if (this.core.jumpToProject === false && this.core.jumpToMenu === false)
                window.addEventListener('click', this.skipHandler);
        });
    }

    awake() {
        const typingArea = document.getElementById('typingIntro');
        this.typingText = typingArea.querySelector('span');
        this.typingText.innerText = '';

        this.isIntroStarted = false;
        this.isIntroFinished = false;
        this.isLoadFinished = false;

        this.copyTextStr = 'DIGITAL DESIGN NINEFIVE';
        this.currentIntervalTime = 0;
        this.currentSubstringIndex = 0;
        this.textContent = '';
        this.typeInterval = 0.05;

        this.isMac = /Mac/i.test(window.navigator.userAgent);
    }

    update() {
        if (this.isIntroStarted === false || this.isIntroFinished)
            return;

        this.onUpdate();
    }

    onIntroFinishCallback() {
        if (this.isIntroFinished && this.isLoadFinished) {
            if (this.skipAnim)
                this.skipAnim.pause();

            this.typingText.classList.add('hide');
            window.removeEventListener('click', this.skipHandler);
            this.event.trigger(this.event.INTRO_FINISHED, [this.skipAnim != undefined]);
        }
    }

    onUpdate() {
        this.deltaTime = this.core.deltaTime;

        this.currentIntervalTime += this.deltaTime;
        if (this.currentIntervalTime > this.typeInterval) {
            this.currentIntervalTime = 0;
            const targetText = this.copyTextStr;
            this.textContent += targetText.substring(this.currentSubstringIndex, this.currentSubstringIndex + 1);
            this.typingText.innerText = this.textContent;
            ++this.currentSubstringIndex;
            if (this.currentSubstringIndex > targetText.length - 1) {
                this.currentIntervalTime = 0;
                this.currentSubstringIndex = 0;

                this.isIntroFinished = true;
                this.onIntroFinishCallback();
            }
        }
    }

    skip() {
        if (this.skipAnim || this.isLoadFinished === false)
            return;

        if (this.coroutineId != undefined)
            clearTimeout(this.coroutineId);

        this.skipAnim = gsap.to({ dummy: 0 }, {
            dummy: 0,
            duration: this.core.deltaTime,
            onStart: () => {
                this.isIntroFinished = true;
                this.onIntroFinishCallback();
            }
        });
    }
}