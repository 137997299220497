
import * as THREE from 'three';
import gsap from 'gsap';
import Event from './Event';


export default class CustomOrbitControls {
    constructor(_options) {
        this.targetObject = _options.targetObject;
        this.event = new Event();

        this.event.on(this.event.TRIGGER_AUTOROTATE_STOP, () => {
            this.triggerStopAutoRotate();
        });

        this.initialize();
    }

    initialize() {
        this.forIntro = false;
        this.enabled = true;
        this.enableAutoRotate = true;
        this.autoRotateWaitingTime = 2;
        this.autoRotateSpeed = -0.05;
        this.latestPointerTime = 0;
        this.initCursorPos = { x: 0, y: 0 };
        this.cursorPos = { x: 0, y: 0 };
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

        // NOTE-240214      얘만 초기화 값이 다른 이유는 SphereStructure의 초기 회전값이 있기 때문
        this.initTargetRotation = { x: THREE.MathUtils.degToRad(10), y: THREE.MathUtils.degToRad(57.5) };

        this.targetRotationOnDown = { x: 0, y: 0 };
        this.targetRotation = { x: this.initTargetRotation.x, y: this.initTargetRotation.y };
        this.clock = new THREE.Clock();
        this.previousTime = 0;
        this.orbitOptions = { damping: 0.1, rotateSpeed: 0.005 };

        this.onPointerMove = function (event) {
            if (this.isMobile === false && event.isPrimary === false)
                return;

            const posX = this.isMobile ? event.targetTouches[0].clientX : event.clientX;
            const posY = this.isMobile ? event.targetTouches[0].clientY : event.clientY;

            this.triggerStopAutoRotate();
            this.cursorPos.x = posX - window.innerWidth * 0.5;
            this.targetRotation.y = this.targetRotationOnDown.y + (this.cursorPos.x - this.initCursorPos.x) * this.orbitOptions.rotateSpeed;

            this.cursorPos.y = posY - window.innerHeight * 0.5;
            this.targetRotation.x = this.targetRotationOnDown.x + (this.cursorPos.y - this.initCursorPos.y) * this.orbitOptions.rotateSpeed;
        };
        this.onPointerMoveHandler = this.onPointerMove.bind(this);

        this.onPointerUp = function (event) {
            if (this.isMobile === false && event.isPrimary === false)
                return;

            if (this.isMobile) {
                document.removeEventListener('touchmove', this.onPointerMoveHandler);
                document.removeEventListener('touchend', this.onPointerUpHandler);
            }
            else {
                document.removeEventListener('pointermove', this.onPointerMoveHandler);
                document.removeEventListener('pointerup', this.onPointerUpHandler);
            }
        };
        this.onPointerUpHandler = this.onPointerUp.bind(this);

        if (this.isMobile) {
            window.addEventListener('touchstart', (event) => {
                this.onPointerDown(event);
            });
        }
        else {
            window.addEventListener('pointerdown', (event) => {
                this.onPointerDown(event);
            });
        }
    }

    update() {
        const elapsedTime = this.clock.getElapsedTime();
        const deltaTime = elapsedTime - this.previousTime;
        this.previousTime = elapsedTime;

        if (this.forIntro) {
            this.targetObject.rotation.y += deltaTime * this.autoRotateSpeed;
            this.targetRotation.y = this.targetObject.rotation.y;
            return;
        }

        if (this.enabled === false || this.targetObject === null || typeof this.targetObject == undefined) {
            this.triggerStopAutoRotate();
            return;
        }

        if (this.enableAutoRotate && elapsedTime - this.latestPointerTime > this.autoRotateWaitingTime) {
            this.targetObject.rotation.y += deltaTime * this.autoRotateSpeed;
            this.targetRotation.y = this.targetObject.rotation.y;
        }
        else {
            this.targetObject.rotation.x += (this.targetRotation.x - this.targetObject.rotation.x) * this.orbitOptions.damping;
            this.targetObject.rotation.y += (this.targetRotation.y - this.targetObject.rotation.y) * this.orbitOptions.damping;
        }

        // NOTE-240220      -360~360을 벗어나면 보정해줌
        if (this.targetRotation.x < THREE.MathUtils.degToRad(-360)
            && this.targetObject.rotation.x < THREE.MathUtils.degToRad(-360)) {
            this.targetRotation.x += THREE.MathUtils.degToRad(360);
            this.targetObject.rotation.x += THREE.MathUtils.degToRad(360);
            this.targetRotationOnDown.x += THREE.MathUtils.degToRad(360);
        }
        else if (this.targetRotation.x > THREE.MathUtils.degToRad(360)
            && this.targetObject.rotation.x > THREE.MathUtils.degToRad(360)) {
            this.targetRotation.x -= THREE.MathUtils.degToRad(360);
            this.targetObject.rotation.x -= THREE.MathUtils.degToRad(360);
            this.targetRotationOnDown.x -= THREE.MathUtils.degToRad(360);
        }

        if (this.targetRotation.y < THREE.MathUtils.degToRad(-360)
            && this.targetObject.rotation.y < THREE.MathUtils.degToRad(-360)) {
            this.targetRotation.y += THREE.MathUtils.degToRad(360);
            this.targetObject.rotation.y += THREE.MathUtils.degToRad(360);
            this.targetRotationOnDown.y += THREE.MathUtils.degToRad(360);
        }
        else if (this.targetRotation.y > THREE.MathUtils.degToRad(360)
            && this.targetObject.rotation.y > THREE.MathUtils.degToRad(360)) {
            this.targetRotation.y -= THREE.MathUtils.degToRad(360);
            this.targetObject.rotation.y -= THREE.MathUtils.degToRad(360);
            this.targetRotationOnDown.y -= THREE.MathUtils.degToRad(360);
        }
        // //
    }

    triggerStopAutoRotate() {
        this.latestPointerTime = this.clock.getElapsedTime();
    }

    onPointerDown(event) {
        if (this.enabled === false)
            return;

        const posX = this.isMobile ? event.targetTouches[0].clientX : event.clientX;
        const posY = this.isMobile ? event.targetTouches[0].clientY : event.clientY;

        this.triggerStopAutoRotate();
        this.initCursorPos.x = posX - window.innerWidth * 0.5;
        this.targetRotationOnDown.y = this.targetRotation.y;

        this.initCursorPos.y = posY - window.innerHeight * 0.5;
        this.targetRotationOnDown.x = this.targetRotation.x;

        if (this.isMobile) {
            document.addEventListener('touchmove', this.onPointerMoveHandler);
            document.addEventListener('touchend', this.onPointerUpHandler);
        }
        else {
            document.addEventListener('pointermove', this.onPointerMoveHandler);
            document.addEventListener('pointerup', this.onPointerUpHandler);
        }
    }

    resetToInit() {
        gsap.to(this.targetObject.rotation, {
            x: this.initTargetRotation.x,
            y: this.initTargetRotation.y,
            duration: 0.5,
            onComplete: () => {
                this.targetRotation.x = this.targetObject.rotation.x;
                this.targetRotation.y = this.targetObject.rotation.y;
            },
        });
    }
}